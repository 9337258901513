import { toast } from "react-toastify";
import { ID, base_url } from "./utils";
import store from "../redux/store";
import { setToken } from "../redux/slice/usersSlice";
import {
  get_cities_list,
  get_leaders_list,
  get_logo_list,
  get_page_list,
} from "../redux/slice/homeSlice";

export const login_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/login/", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("userId", JSON.stringify(res));
      console.log(res.user.token, "res.user.token");
      store.dispatch(setToken(res.user.token));
      store.dispatch(get_page_list());
      window.location.reload();
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

const user = JSON.parse(localStorage.getItem("userId"));

export const delete_city = async (id, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/city/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      store.dispatch(get_cities_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};

export const city_actions = async (endPoint, body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/city/${endPoint}`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const add_review = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/reviews/add`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const edit_review = async (id, body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(
      base_url + `api/reviews/update-info/id/${id}`,
      {
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const delete_review = async (id, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/reviews/delete/id/${id}`, {
      method: "DELETE",

      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const update_logo_priority = async (id, priority) => {
  try {
    // setLoading(true);
    const response = await fetch(
      base_url + `api/members-logos/update/id/priority/${id}/${priority}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    // setLoading(false);
    if (res.success) {
      store.dispatch(get_logo_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    // setLoading(false);
  }
};

export const add_logo = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/members-logos/add`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const delete_logo = async (id, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(
      base_url + `api/members-logos/delete/id/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      store.dispatch(get_logo_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};

export const update_leader_priority = async (id, priority) => {
  try {
    // setLoading(true);
    const response = await fetch(
      base_url + `api/leaders/update/id/priority/${id}/${priority}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    // setLoading(false);
    if (res.success) {
      store.dispatch(get_leaders_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    // setLoading(false);
  }
};

export const add_api = async (endPoint, body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/${endPoint}`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const delete_api = async (endPoint, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/${endPoint}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_api = async (endPoint, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/${endPoint}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const put_api = async (endPoint, body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + `api/${endPoint}`, {
      method: "PUT",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
