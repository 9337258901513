import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import {
  delete_product,
  get_page_list,
  get_products_list,
  update_product_priority,
  update_product_status,
} from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import ImageModal from "../../components/ImageModal";
import CustomPopup from "../../components/CustomPopup";
import { toast } from "react-toastify";

const ProductList = () => {
  const navigate = useNavigate();
  const { product_list, product_loading, page_list } = useSelector(
    (state) => state.homeData
  );
console.log("product_list ====",product_list);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [data, setData] = useState({});
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const [url, setUrl] = useState("");

  useEffect(() => {
    store.dispatch(get_page_list());
    store.dispatch(get_products_list());
  }, []);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Products List</Heading>
        <CustomButton
          title={"Add Product"}
          onClick={() => navigate("add-product")}
        />
      </Flex>
      <Spacer h={3} />
      {product_loading && product_list?.length === 0 ? (
        <Loader />
      ) : product_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Title",
            "DESCRIPTION",
            "Image",
            "STATUS",
            "PRIORITY",
            "Actions",
          ]}
          body={product_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.TITLE}</Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.DESCRIPTION}
                </Text>
              </Td>
              <Td
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                display={"flex"}
              >
                <Image
                  src={base_url + item.IMAGE_URL}
                  h={20}
                  w={20}
                  cursor={"pointer"}
                  resize={"initial"}
                  onClick={() => {
                    setUrl(base_url + item.IMAGE_URL);
                    onViewOpen();
                  }}
                />
              </Td>
              <Td textAlign={"center"}>
                <RadioGroup
                value={item?.STATUS}
                onChange={(e)=>{
                  console.log("NP");
                  store.dispatch(
                    update_product_status({
                      id: item.ID,
                      status: e,
                    })
                  )
                }}
                >
                  <Stack direction="row"
                  >
                    <Radio value="1">Enquire Now</Radio>
                    <Radio value="2">Book Now</Radio>
                  </Stack>
                </RadioGroup>
              </Td>
              <Td textAlign={"center"}>
                <Flex justify={"center"}>
                  {item.ID == 30 || item.ID == 29 ? (
                    item.PRIORITY
                  ) : (
                    <Select
                      w={"100px"}
                      value={item.PRIORITY}
                      size={"md"}
                      borderRadius={5}
                      onChange={(v) =>
                        store.dispatch(
                          update_product_priority({
                            id: item.ID,
                            priority: v.target.value,
                          })
                        )
                      }
                    >
                      {Array(product_list?.length + 1)
                        ?.fill("")
                        ?.map(
                          (v, i) =>
                            i == 5 || i == 6 || <option value={i}>{i}</option>
                        )}
                    </Select>
                  )}
                </Flex>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      onPOpen();
                    }}
                  />
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => navigate("edit-product", { state: item })}
                  />

                  {item.ID == 30 || item.ID == 29 || (
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        setId(item.ID);
                        onOpen();
                      }}
                    />
                  )}
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete product details"}
        onClick={() => {
          store
            .dispatch(delete_product(id))
            .unwrap()
            .then((v) => {
              if (v.success) {
                toast.success(v.message);
                store.dispatch(get_products_list());
                onClose();
              } else {
                toast.error(v.message);
              }
            });
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Modal
        isOpen={isPOpen}
        onClose={() => {
          onPClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.md"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Heading fontSize={25}>Product Details</Heading>
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
              Title
            </Text>
            <Text
              bg={"#c1c1c144"}
              p={2}
              fontSize={16}
              fontWeight={"medium"}
              color={"#2B2B2B"}
            >
              {data.TITLE}
            </Text>
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
              DESCRIPTION{" "}
            </Text>
            <Text
              bg={"#c1c1c144"}
              p={2}
              fontSize={16}
              fontWeight={"medium"}
              color={"#2B2B2B"}
            >
              {data.DESCRIPTION}
            </Text>
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
              Key Features{" "}
            </Text>
            <Stack bg={"#c1c1c144"} p={2}>
              <UnorderedList spacing={1}>
                {data?.FEATURES?.map((v, i) => (
                  <ListItem
                    fontSize={16}
                    fontWeight={"medium"}
                    color={"#2B2B2B"}
                  >
                    {v}
                  </ListItem>
                ))}
              </UnorderedList>
            </Stack>
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
              PRIORITY{" "}
            </Text>
            <Text
              bg={"#c1c1c144"}
              p={2}
              fontSize={16}
              fontWeight={"medium"}
              color={"#2B2B2B"}
            >
              {data.PRIORITY}
            </Text>
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={2}>
              IMAGE_URL{" "}
            </Text>
            <Image
              src={base_url + data.IMAGE_URL}
              h={200}
              w={200}
              cursor={"pointer"}
              resize={"initial"}
              onClick={() => {
                setUrl(base_url + data.IMAGE_URL);
                onViewOpen();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default ProductList;
