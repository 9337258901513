import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiUsers } from "react-icons/fi";
import { BiMessage } from "react-icons/bi";
import { TbReport } from "react-icons/tb";
import { FaList, FaListUl } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  return (
    <VStack align={"start"}>
      {/* <Link
        to={"/"}
        className={
          location.pathname.split("/")[1] === "" ? "activelink" : "link"
        }
      >
        {/* <RxDashboard size={20} /> *
        Dashboard
      </Link> */}
      <Link
        to={"/slider"}
        className={
          location.pathname.split("/")[1] === "slider" ? "activelink" : "link"
        }
      >
        <FaList size={20} />
        Slider
      </Link>
      <Link
        to={"/location"}
        className={
          location.pathname.split("/")[1] === "location" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Location
      </Link>
      <Link
        to={"/products"}
        className={
          location.pathname.split("/")[1] === "products" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Product
      </Link>
      <Link
        to={"/contacts"}
        className={
          location.pathname.split("/")[1] === "contacts" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        User Enquiry
      </Link>
      <Link
        to={"/cities"}
        className={
          location.pathname.split("/")[1] === "cities" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        City
      </Link>
      <Link
        to={"/reviews"}
        className={
          location.pathname.split("/")[1] === "reviews" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Review
      </Link>
      <Link
        to={"/logos"}
        className={
          location.pathname.split("/")[1] === "logos" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Company Logos
      </Link>
      <Link
        to={"/leaders"}
        className={
          location.pathname.split("/")[1] === "leaders" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Leaders
      </Link>
      <Link
        to={"/meeting-event"}
        className={
          location.pathname.split("/")[1] === "meeting-event"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Meeting & Event
      </Link>
      <Link
        to={"/unforgettable-event"}
        className={
          location.pathname.split("/")[1] === "unforgettable-event"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Unforgettable Event
      </Link>
      <Link
        to={"/gallery-images"}
        className={
          location.pathname.split("/")[1] === "gallery-images"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Gallery Image
      </Link>
      <Link
        to={"/choose-altis"}
        className={
          location.pathname.split("/")[1] === "choose-altis"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Choose Altis
      </Link>
      <Link
        to={"/amenties-faci"}
        className={
          location.pathname.split("/")[1] === "amenties-faci"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Amenties & Faci
      </Link>
      <Link
        to={"/career-query"}
        className={
          location.pathname.split("/")[1] === "career-query"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Career Query
      </Link>
      <Link
        to={"/event-query"}
        className={
          location.pathname.split("/")[1] === "event-query"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Event Query
      </Link>
      <Link
        to={"/dropdown"}
        className={
          location.pathname.split("/")[1] == "dropdown" ? "activelink" : "link"
        }
      >
        <FaListUl size={20} />
        Dropdown
      </Link>
    </VStack>
  );
};

export default Sidebar;
