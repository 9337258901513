import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import { add_api, delete_api, update_leader_priority } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import { get_leaders_list } from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";

const LeadersList = () => {
  const navigate = useNavigate();
  const { leaders_list, leaders_loading, page_list } = useSelector(
    (state) => state.homeData
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_leaders_list());
  }, []);

  const onAddLeader = async () => {
    if (
      !data.NAME ||
      !data.DESCRIPTION ||
      !data.DESIGNATION ||
      !data.LINKEDIN_URL ||
      (!edit && !data.img)
    ) {
      setData({
        ...data,
        nameMess: !data.NAME ? "Name is Required" : "",
        DESCRIPTIONMess: !data.DESCRIPTION ? "DESCRIPTION is Required" : "",
        DESIGNATIONMess: !data.DESIGNATION ? "Designation is Required" : "",
        LINKEDIN_URLMess: !data.LINKEDIN_URL ? "Linkedin url is Required" : "",
        imgMess: !edit && !data.img ? "Image is Required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("NAME", data.NAME);
    body.append("DESCRIPTION", data.DESCRIPTION);
    body.append("PRIORITY", !data.PRIORITY ? 0 : data.PRIORITY);
    body.append("DESIGNATION", data.DESIGNATION);
    body.append("IMAGE", data.img);
    body.append("LINKEDIN_URL", data.LINKEDIN_URL);
    edit && body.append("ID", data.ID);
    const res = await add_api(
      edit ? "leaders/update" : "leaders/add",
      body,
      setLoading
    );
    if (res.success) {
      store.dispatch(get_leaders_list());
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const list = [];
  for (let i = 0; i < page_list.length; i++) {
    const element = {
      label: page_list[i].PAGE_NAME,
      value: page_list[i].PAGE_NAME,
    };
    list.push(element);
  }
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Leaders List</Heading>
        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />

      {leaders_loading && leaders_list?.length === 0 ? (
        <Loader />
      ) : leaders_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "NAME",
            "DESIGNATION",
            "Image",
            "DESCRIPTION",
            "LINKEDIN URL",
            "PRIORITY",
            "Actions",
          ]}
          body={leaders_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.NAME}</Td>
              <Td
                textAlign={"center"}
                minW={"300px"}
                maxW={"300px"}
                whiteSpace={"pre-wrap"}
              >
                {item.DESIGNATION}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Image
                    onClick={() => {
                      setUrl(base_url + item.IMAGE_URL);
                      onViewOpen();
                    }}
                    src={base_url + item.IMAGE_URL}
                    height={"60px"}
                    w={"60px"}
                    objectFit={"cover"}
                  />
                </Center>
              </Td>
              <Td
                textAlign={"center"}
                minW={"350px"}
                maxW={"350px"}
                whiteSpace={"pre-wrap"}
              >
                {item.DESCRIPTION}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.LINKEDIN_URL}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Select
                    w={"100px"}
                    value={item.PRIORITY}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) =>
                      update_leader_priority(item.ID, v.target.value)
                    }
                  >
                    {Array(leaders_list?.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Center>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      onPOpen();
                    }}
                  />
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={async () => {
                      setData(item);
                      setEdit(true);
                      onAddOpen();
                    }}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText title={edit ? "Edit Leader Details" : "Add Leader"} />
            <InputBox
              label={"Name"}
              placeholder={"Name"}
              value={data.NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  NAME: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  nameMess: "",
                });
              }}
              error={data?.nameMess}
            />

            <InputBox
              label={"Designation"}
              placeholder={"Designation"}
              value={data.DESIGNATION}
              onChange={(v) => {
                setData({
                  ...data,
                  DESIGNATION: v.target.value.trimStart(),
                  DESIGNATIONMess: "",
                });
              }}
              error={data?.DESIGNATIONMess}
            />
            <InputBox
              label={"Description"}
              placeholder={"Description"}
              area={true}
              value={data.DESCRIPTION}
              onChange={(v) => {
                setData({
                  ...data,
                  DESCRIPTION: v.target.value.trimStart(),
                  DESCRIPTIONMess: "",
                });
              }}
              error={data?.DESCRIPTIONMess}
            />
            <InputBox
              label={"Linkedin URL"}
              placeholder={"Linkedin URL"}
              value={data.LINKEDIN_URL}
              onChange={(v) => {
                setData({
                  ...data,
                  LINKEDIN_URL: v.target.value.trimStart(),
                  LINKEDIN_URLMess: "",
                });
              }}
              error={data?.LINKEDIN_URLMess}
            />

            <SelectImage
              mt={2}
              label={"Image"}
              filename={data?.img?.name}
              onChange={(e) => {
                setData({
                  ...data,
                  img: e.target.files[0],
                  imgMess: "",
                });
              }}
              error={data?.imgMess}
            />
            {edit || (
              <InputBox
                drop={true}
                label={"Priority"}
                value={data.PRIORITY}
                onChange={(v) =>
                  setData({
                    ...data,
                    PRIORITY: v.target.value,
                    PRIORITYmess: "",
                  })
                }
                options={Array(leaders_list?.length + 2)
                  .fill("")
                  ?.map((item, index) => (
                    <option>{index}</option>
                  ))}
              />
            )}

            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddLeader}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Modal
        isOpen={isPOpen}
        onClose={() => {
          onPClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.md"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Heading fontSize={25}>Review Details</Heading>
            <InputBox label={"Name"} value={data.NAME} textonly />
            <InputBox label={"Designation"} value={data.DESIGNATION} textonly />
            <InputBox label={"Description"} value={data.DESCRIPTION} textonly />
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={2}>
              IMAGE_URL
            </Text>
            <Image
              src={base_url + data.IMAGE_URL}
              h={200}
              w={200}
              cursor={"pointer"}
              resize={"initial"}
              onClick={() => {
                setUrl(base_url + data.IMAGE_URL);
                onViewOpen();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`leaders/delete/id/${id}`, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(get_leaders_list());
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to leader details"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default LeadersList;
