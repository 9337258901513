import {
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import { add_review, delete_api, edit_review } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import { get_reviews_list } from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import Select from "react-select";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";

const ReviewList = () => {
  const navigate = useNavigate();
  const { review_list, review_loading } = useSelector(
    (state) => state.homeData
  );
  const page_list = [
    "Home",
    "About",
    "Why Altis",
    "Meeting",
    "Workspace",
    "Career",
    "Product",
  ];
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [c_page, setC_page] = useState(0);
  const [url, setUrl] = useState("");
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_reviews_list(page_list[c_page]));
  }, []);

  const onAddCity = async () => {
    if (
      !data.PAGES ||
      Object.keys(data?.PAGES).length == 0 ||
      !data.COMPANY_NAME ||
      !data.PERSON_NAME ||
      !data.REVIEW ||
      !data.DESIGNATION ||
      (!edit && !data.img)
    ) {
      setData({
        ...data,
        nameMess: !data.COMPANY_NAME ? "Company Name is Required" : "",
        personMess: !data.PERSON_NAME ? "Person Name is Required" : "",
        REVIEWMess: !data.REVIEW ? "Review is Required" : "",
        DESIGNATIONMess: !data.DESIGNATION ? "Designation is Required" : "",
        imgMess: !edit && !data.img ? "Image is Required" : "",
        PAGESmess:
          !data.PAGES || Object.keys(data?.PAGES).length == 0
            ? "Pages are Required"
            : "",
      });
      return;
    }

    const body = new FormData();
    body.append("COMPANY_NAME", data.COMPANY_NAME);
    body.append("PERSON_NAME", data.PERSON_NAME);
    body.append("REVIEW", data.REVIEW);
    body.append("PRIORITY", 0);
    data?.PAGES?.forEach((val) => body.append("PAGES[]", val.label));
    body.append("DESIGNATION", data.DESIGNATION);
    body.append("IMAGE", data.img);
    edit && body.append("ID", data.ID);
    const res = edit
      ? await edit_review(data.ID, body, setLoading)
      : await add_review(body, setLoading);
    if (res.success) {
      store.dispatch(get_reviews_list(page_list[c_page]));
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #f00",
    }),
  };
  const list = [];
  for (let i = 0; i < page_list.length; i++) {
    const element = {
      label: page_list[i],
      value: page_list[i],
    };
    list.push(element);
  }
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Review List</Heading>
        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Text>Page Name</Text>
      <Flex gap={3}>
        {page_list.map((v, i) => (
          <CustomButton
            bg={c_page != i && "gray"}
            title={v}
            onClick={() => {
              setC_page(i);
              // store.dispatch(get_reviews_list(page_list[i].PAGE_NAME));
              store.dispatch(get_reviews_list(v));
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      {review_loading && review_list?.length === 0 ? (
        <Loader />
      ) : review_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "COMPANY_NAME",
            "PERSON_NAME",
            "REVIEW",
            "DESIGNATION",
            "Image",
            "PAGES",
            "Actions",
          ]}
          body={review_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.COMPANY_NAME}</Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.PERSON_NAME}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.REVIEW}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.DESIGNATION}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Image
                  onClick={() => {
                    setUrl(base_url + item.IMAGE_URL);
                    onViewOpen();
                  }}
                  src={base_url + item.IMAGE_URL}
                  height={"60px"}
                  w={"60px"}
                  objectFit={"cover"}
                />
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.PAGES?.toString()}
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      onPOpen();
                    }}
                  />
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={async () => {
                      const list = [];
                      for (let i = 0; i < item.PAGES.length; i++) {
                        const element = {
                          label: item.PAGES[i],
                          value: item.PAGES[i],
                        };
                        list.push(element);
                      }
                      setData({
                        ...data,
                        PAGES: list,
                        PERSON_NAME: item.PERSON_NAME,
                        COMPANY_NAME: item.COMPANY_NAME,
                        REVIEW: item.REVIEW,
                        DESIGNATION: item.DESIGNATION,
                        ID: item.ID,
                      });
                      setEdit(true);
                      onAddOpen();
                    }}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText title={edit ? "Edit Review Details" : "Add Review"} />
            <Flex gap={3} mt={3}>
              <div style={{ flex: 1 }}>
                <FormLabel marginBottom={2} marginLeft={2}>
                  Select Pages
                </FormLabel>
                <Select
                  placeholder={"Search and Select Pages"}
                  options={list}
                  isMulti={true}
                  value={data.PAGES}
                  isSearchable
                  // filterOption={customFilter}
                  onChange={(v) => {
                    setData({
                      ...data,
                      PAGES: v,
                      PAGESmess: "",
                      PAGESval: false,
                    });
                  }}
                  styles={data.PAGESval && customStyles}
                />
                {
                  <Text fontSize={"sm"} color={"#f00"}>
                    {data.PAGESmess}
                  </Text>
                }
              </div>
            </Flex>
            <InputBox
              label={"Company Name"}
              placeholder={"Company Name"}
              value={data.COMPANY_NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  COMPANY_NAME: v.target.value,
                  nameMess: "",
                });
              }}
              error={data?.nameMess}
            />
            <InputBox
              label={"Person Name"}
              placeholder={"Person Name"}
              value={data.PERSON_NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  PERSON_NAME: v.target.value,
                  personMess: "",
                });
              }}
              error={data?.personMess}
            />
            <InputBox
              label={"Review"}
              placeholder={"Review"}
              area={true}
              value={data.REVIEW}
              onChange={(v) => {
                setData({
                  ...data,
                  REVIEW: v.target.value,
                  REVIEWMess: "",
                });
              }}
              error={data?.REVIEWMess}
            />
            <InputBox
              label={"Designation"}
              placeholder={"Designation"}
              value={data.DESIGNATION}
              onChange={(v) => {
                setData({
                  ...data,
                  DESIGNATION: v.target.value,
                  DESIGNATIONMess: "",
                });
              }}
              error={data?.DESIGNATIONMess}
            />
            <SelectImage
              mt={2}
              label={"Image"}
              filename={data?.img?.name}
              onChange={(e) => {
                setData({
                  ...data,
                  img: e.target.files[0],
                  imgMess: "",
                });
              }}
              error={data?.imgMess}
            />

            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddCity}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Modal
        isOpen={isPOpen}
        onClose={() => {
          onPClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.md"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Heading fontSize={25}>Review Details</Heading>
            <InputBox
              textonly={true}
              label={"Company Name"}
              value={data.COMPANY_NAME}
            />
            <InputBox
              textonly={true}
              label={"Person Name"}
              value={data.PERSON_NAME}
            />
            <InputBox textonly={true} label={"Review"} value={data.REVIEW} />
            <InputBox
              textonly={true}
              label={"Page Names"}
              value={data.PAGES?.toString()}
            />
            <InputBox
              textonly={true}
              label={"Designation"}
              value={data.DESIGNATION}
            />
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={2}>
              IMAGE_URL
            </Text>
            <Image
              src={base_url + data.IMAGE_URL}
              h={200}
              w={200}
              cursor={"pointer"}
              resize={"initial"}
              onClick={() => {
                setUrl(base_url + data.IMAGE_URL);
                onViewOpen();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`reviews/delete/id/${id}`, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(get_reviews_list(page_list[c_page]));
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to leader details"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default ReviewList;
