import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Td,
  Tr,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import {
  add_logo,
  delete_api,
  get_api,
  update_logo_priority,
} from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import { get_logo_list } from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";
const list = ["Home", "Why Altis"];

const LogoList = () => {
  const navigate = useNavigate();
  const { logo_list, logo_loading } = useSelector((state) => state.homeData);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const [c_page, setC_page] = useState(0);
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    store.dispatch(get_logo_list(list[c_page]));
  }, []);

  const onAddlogo = async () => {
    if (!data.img || !data.pagename) {
      setData({
        ...data,
        imgMess: !data.img ? "Logo is Required" : "",
        pagenameMess: !data.pagename ? "Page name is Required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("PRIORITY", data.PRIORITY);
    body.append("PAGE_NAME", data.pagename);
    body.append("IMAGE", data.img);
    const res = await add_logo(body, setLoading);
    if (res.success) {
      store.dispatch(get_logo_list(list[c_page]));
      onAddClose();
      setData({});
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Logo List</Heading>

        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Text>Page Name</Text>
      <Flex gap={3}>
        {list.map((v, i) => (
          <CustomButton
            bg={c_page != i && "gray"}
            title={v}
            onClick={() => {
              setC_page(i);
              store.dispatch(get_logo_list(v));
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      {logo_loading && logo_list?.length === 0 ? (
        <Loader />
      ) : logo_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "LOGO_URL", "PRIORITY", "Actions"]}
          body={logo_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td justifyContent={"center"} display={"flex"}>
                <Image
                  onClick={() => {
                    setUrl(base_url + item.LOGO_URL);
                    onViewOpen();
                  }}
                  src={base_url + item.LOGO_URL}
                  height={"80px"}
                  w={"80px"}
                  objectFit={"contain"}
                />
              </Td>
              <Td textAlign={"center"} maxW={"300px"}>
                <Center>
                  <Select
                    w={"100px"}
                    value={item.PRIORITY}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) =>
                      get_api(
                        `members-logos/update/id/priority/${item.ID}/${v.target.value}`,
                        setLoading
                      ).then((res) => {
                        if (res.success) {
                          store.dispatch(get_logo_list(list[c_page]));
                          toast.success(res.message);
                        } else {
                          toast.error(res.message);
                        }
                      })
                    }
                  >
                    {Array(logo_list?.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Center>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText title={"Add Company Logo"} />
            <InputBox
              drop={true}
              label={"Page Name"}
              placeholder={"Page Name"}
              value={data?.pagename}
              onChange={(v) => {
                setData({
                  ...data,
                  pagename: v.target.value,
                  pagenameMess: "",
                });
              }}
              error={data?.pagenameMess}
              options={list?.map((v) => (
                <option value={v}>{v}</option>
              ))}
            />
            <SelectImage
              mt={2}
              label={"Logo"}
              filename={data?.img?.name}
              onChange={(e) => {
                setData({
                  ...data,
                  img: e.target.files[0],
                  imgMess: "",
                });
              }}
              error={data?.imgMess}
            />

            <InputBox
              mt={0.1}
              drop={true}
              label={"Priority"}
              value={data.PRIORITY}
              onChange={(v) =>
                setData({
                  ...data,
                  PRIORITY: v.target.value,
                  PRIORITYmess: "",
                })
              }
              options={Array(logo_list?.length || 0 + 1)
                .fill("")
                ?.map((item, index) => (
                  <option>{index}</option>
                ))}
            />

            <Center mt={5} mb={2}>
              <CustomButton
                title={"Submit"}
                loading={loading}
                onClick={onAddlogo}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`members-logos/delete/id/${id}`, setLoading).then(
            (res) => {
              if (res.success) {
                store.dispatch(get_logo_list(list[c_page]));
                toast.success(res.message);
              } else {
                toast.error(res.message);
              }
            }
          );
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to delete company logo"}
      />
    </Container>
  );
};

export default LogoList;
