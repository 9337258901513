import {
  Card,
  Center,
  Container,
  Flex,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import HeadingText from "../../components/HeadingText";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import InputBox from "../../components/InputBox";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import { add_banner, get_page_list } from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";

const AddBanner = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [keyf, setKeyf] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { page_list, add_banner_loading } = useSelector(
    (state) => state.homeData
  );
  const [p_name, setP_name] = useState("");
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  const onAddSlider = () => {
    if (!data.p_name || !data.title || !data.img || keyFeatures.length == 0) {
      setData({
        ...data,
        p_namemess: !data.p_name ? "Page Name is required" : "",
        titlemess: !data.title ? "Title is required" : "",
        imgmess: !data.img ? "Image is required" : "",
      });
      setKeyf({
        ...keyf,
        keymess: keyFeatures.length == 0 ? "Typing Message are required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("image", data.img);
    body.append("staticText", data.title);
    body.append("pageName", data.p_name);
    keyFeatures.forEach((v) => body.append("dynamicText[]", v));
    store
      .dispatch(add_banner(body))
      .unwrap()
      .then((v) => {
        if (v.success) {
          toast.success(v.message);
          navigate(-1);
        } else toast.error(v.message);
      });
  };

  const onAddPageName = async () => {
    if (!p_name) {
      toast.info("Please enter page name");
      return;
    }
    try {
      const body = new FormData();
      body.append("pageName", p_name);
      setLoading(true);
      const response = await fetch(base_url + "api/pages-declaration/add", {
        body: body,
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      });
      const res = await response.json();
      setLoading(false);

      if (res.success) {
        toast.success(res.message);
        store.dispatch(get_page_list());
        setP_name("");
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Slider Details"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1} gap={3}>
            <InputBox
              mt={0.1}
              drop={true}
              label={"Page Name"}
              placeholder={"Page Name"}
              value={data.p_name}
              onChange={(v) =>
                setData({
                  ...data,
                  p_name: v.target.value,
                  p_namemess: "",
                })
              }
              options={page_list?.map((item, index) => (
                <option>{item.PAGE_NAME}</option>
              ))}
              error={data.p_namemess}
            />
            <CustomButton title={"Add Page"} mt={9} onClick={onOpen} />
          </Flex>
          <SelectImage
            label={"Image"}
            doctype={".png,.jpg,.jpeg"}
            filename={data.img?.name}
            onChange={(e) =>
              setData({
                ...data,
                img: e.target.files[0],
                imgval: false,
                imgmess: "",
              })
            }
            errorBorder={data.imgval}
            error={data.imgmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Static Text"}
            placeholder={"Static Text"}
            value={data.title}
            onChange={(v) =>
              setData({
                ...data,
                title: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                titlemess: "",
              })
            }
            error={data.titlemess}
          />
          <Flex flex={1} flexDirection={"column"}>
            <Flex flex={1} alignItems={"center"} gap={3}>
              <InputBox
                label={"Typing Message"}
                placeholder={"Typing Message"}
                value={keyf.key}
                onChange={(v) =>
                  setKeyf({
                    ...keyf,
                    key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                    keymess: "",
                  })
                }
              />
              <CustomButton
                mt={9}
                title={"Add"}
                onClick={() => {
                  setKeyFeatures([...keyFeatures, keyf.key]);
                  setKeyf({
                    ...keyf,
                    key: "",
                  });

                  // setKeyf({});
                  // onOpen();
                }}
              />
            </Flex>
            <Stack bg={"#fff"} flex={1} p={2}>
              <UnorderedList spacing={1}>
                {keyFeatures?.map((v, i) => (
                  <Flex>
                    <ListItem
                      flex={1}
                      fontSize={16}
                      fontWeight={"medium"}
                      color={"#2B2B2B"}
                    >
                      {v}
                    </ListItem>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        const fitered = keyFeatures.filter(
                          (val, ind) => ind != i
                        );
                        setKeyFeatures(fitered);
                      }}
                    />
                  </Flex>
                ))}
              </UnorderedList>
              {keyf.keymess && (
                <Text fontSize={"sm"} color={"#f00"}>
                  {keyf.keymess}
                </Text>
              )}
            </Stack>
          </Flex>
        </Flex>

        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={add_banner_loading}
            onClick={() => onAddSlider()}
          />
        </Center>
      </Card>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Add Page Name"}
        mess={
          <InputBox
            value={p_name}
            onChange={(v) =>
              setP_name(v.target.value.trim().replace(/[^a-zA-Z0-9 ]/g, ""))
            }
            placeholder={"Page Name"}
          />
        }
        b_name={"Add"}
        b_color={"#FFBF00"}
        onClick={() => {
          onAddPageName();
        }}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default AddBanner;
