import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import {
  add_api,
  delete_api,
  delete_review,
  get_api,
  put_api,
  update_leader_priority,
} from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";

import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";
import { get_gallery_images_list } from "../../redux/slice/homeSlice";

const GalleryImageList = () => {
  const navigate = useNavigate();
  const { gallery_images_list, gallery_images_loading } = useSelector(
    (state) => state.homeData
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const [images, setImages] = useState([]);
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [item, setItem] = useState({});
  const [c_page, setC_page] = useState("Why Altis");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_gallery_images_list(c_page));
  }, []);

  const onAddData = async () => {
    if (gallery_images_list?.length == 0 && images?.length < 5) {
      toast.info("Please select minimum 5 images");
      return;
    }
    if (gallery_images_list?.length != 0 && images?.length < 1) {
      toast.info("Please select image");
      return;
    }
    const body = new FormData();
    gallery_images_list?.length != 0 || body.append("PAGE_NAMES", c_page);
    images.forEach((v) => body.append("PHOTOS", v));
    gallery_images_list?.length != 0 &&
      body.append("ID", gallery_images_list[0]?.ID);
    const res = await add_api(
      gallery_images_list?.length != 0
        ? "eventGallery/add-images"
        : "eventGallery/add",
      body,
      setLoading
    );
    if (res.success) {
      store.dispatch(get_gallery_images_list(c_page));
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const update_priority = async (id, id1) => {
    const body = new FormData();
    body.append("ID", gallery_images_list[0].ID);
    body.append("PATH_ID", id);
    body.append("PRIORITY", id1);
    const res = await put_api(
      `eventGallery/update/image/priority`,
      body,
      setLoading
    );
    if (res.success) {
      store.dispatch(get_gallery_images_list(c_page));
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>Gallery Image</Heading>

      <Spacer h={3} />
      <Text>Page Name</Text>
      <Flex gap={3}>
        {["Why Altis", "Event"].map((v, i) => (
          <CustomButton
            bg={c_page != v && "gray"}
            title={v}
            onClick={() => {
              setC_page(v);
              store.dispatch(get_gallery_images_list(v));
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      <Flex justify={"end"}>
        <CustomButton
          title={"Add Images"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />
      {gallery_images_loading && gallery_images_list?.length === 0 ? (
        <Loader />
      ) : gallery_images_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Image", "PRIORITY", "Actions"]}
          body={gallery_images_list[0]?.IMAGE_URLS?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>

              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Image
                    onClick={() => {
                      setUrl(base_url + item.path);
                      onViewOpen();
                    }}
                    src={base_url + item.path}
                    height={"60px"}
                    w={"60px"}
                    objectFit={"cover"}
                  />
                </Center>
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Select
                    w={"100px"}
                    value={item.priority}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) =>
                      update_priority(item.path_id, v.target.value)
                    }
                  >
                    {Array(gallery_images_list[0]?.IMAGE_URLS?.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Center>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.path_id);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText
              title={edit ? "Edit Gallery Image Details" : "Add Gallery Image"}
            />
            <InputBox textonly={true} label={"Page Name"} value={c_page} />
            <Flex flex={1} flexDirection={"column"}>
              <SelectImage
                mt={2}
                multiple={true}
                label={"Image (Select Multiple Images)"}
                doctype={".png,.jpg,.jpeg"}
                onChange={(e) => {
                  setImages([...e.target.files]);
                  setData({
                    ...data,
                    imgmess: "",
                  });
                }}
                // errorBorder={data.imgval}
                error={data.imgmess}
              />
              <Stack bg={"#fff"} flex={1} p={2}>
                <UnorderedList spacing={1}>
                  {images?.map((v, i) => (
                    <Flex>
                      <ListItem
                        flex={1}
                        fontSize={16}
                        fontWeight={"medium"}
                        color={"#2B2B2B"}
                      >
                        {v.name}
                      </ListItem>
                      <FiTrash2
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          const fitered = images.filter((val, ind) => ind != i);
                          setImages(fitered);
                        }}
                      />
                    </Flex>
                  ))}
                </UnorderedList>
              </Stack>
            </Flex>

            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddData}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          if (gallery_images_list[0]?.IMAGE_URLS?.length < 6) {
            toast.info("Minimum 5 images are required");
            return;
          }
          const body = new FormData();
          body.append("ID", gallery_images_list[0].ID);
          body.append("PATH_ID", id);
          put_api(`eventGallery/image-delete`, body, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(get_gallery_images_list(c_page));
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to image"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default GalleryImageList;
