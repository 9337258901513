import { Box, Container, Flex, Spacer, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = () => {
  const { sidebar_width } = useSelector((state) => state.other);

  return (
    <Container h={"100vh"} w={"100vw"} maxW={"100vw"} padding={0}>
      <Flex
        bg={"#fff"}
        w={"full"}
        h={"60px"}
        borderBottom={"1px solid #88888822 "}
      >
        <Header />
      </Flex>
      <Flex w={"full"} overflowY={"auto"} h={"calc(100vh - 60px)"}>
        <VStack
          display={["none", "none", "none", "flex"]}
          bg={"#fff"}
          h={"full"}
        >
          <div
            style={{
              position: "relative",
              width: sidebar_width ? "13rem" : "3rem",
              transition: "width ease 1s",
              borderRight: "1px solid #88888822 ",
              overflowY: "scroll",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <Sidebar />
          </div>
        </VStack>
        <VStack w={"full"} overflowY={"scroll"} py={3} bg={"#FAFBFE"}>
          <Outlet />
          {/* <Flex h={8} w={100} bg={"transparent"} /> */}
        </VStack>
      </Flex>
    </Container>
  );
};

export default Layout;
