import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const get_banner_list = createAsyncThunk("banner", async (name) => {
  const response = await fetch(base_url + `api/slider-info/pagename/${name}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});

export const update_banner_priority = createAsyncThunk(
  "upbanner",
  async (data) => {
    const response = await fetch(
      base_url +
        `api/slider-info//update/id-priority/${data.id}/${data.priority}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_banner_list(data.name));
    } else {
      toast.error(res.message);
    }
  }
);
export const add_banner = createAsyncThunk("addbanner", async (body) => {
  const response = await fetch(base_url + `api/slider-info/add`, {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
export const edit_banner = createAsyncThunk("editbanner", async (body) => {
  const response = await fetch(base_url + `api/slider-info/update`, {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
export const delete_banner = createAsyncThunk("deletebanner", async (data) => {
  const response = await fetch(base_url + `api/slider-info/id/${data.id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  if (res.success) {
    toast.success(res.message);
    store.dispatch(get_banner_list(data.name));
  } else {
    toast.error(res.message);
  }
  return res;
});

export const get_page_list = createAsyncThunk("pages", async (id) => {
  const response = await fetch(base_url + "api/pages-declaration/list", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});

// --------------------------------------------------------list location----------------------------------------------------------------------

export const get_location_list = createAsyncThunk("location", async (id) => {
  const response = await fetch(base_url + "api/location-workspace/list", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  console.log(res);
  return res;
});
// -------------------------------------------------------- add location----------------------------------------------------------------------

export const add_location = createAsyncThunk("addlocation", async (body) => {
  const response = await fetch(base_url + "api/location-workspace/add?", {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});

// -------------------------------------------------------- update location----------------------------------------------------------------------
export const update_location = createAsyncThunk(
  "updatelocation",
  async (body) => {
    const response = await fetch(base_url + "api/location-workspace/update", {
      method: "PUT",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);

// -------------------------------------------------------- update location image priority----------------------------------------------------------------------
export const update_location_img_priority = createAsyncThunk(
  "updatepriority",
  async (body) => {
    console.log(body);
    const response = await fetch(
      base_url + "api/location-workspace/update/locations/image/priority",
      {
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_location_list());
    } else {
      toast.error(res.message);
    }
  }
);
// -------------------------------------------------------- delete location image----------------------------------------------------------------------
export const delete_location_img_priority = createAsyncThunk(
  "dellocationimage",
  async (body) => {
    const response = await fetch(
      base_url + "api/location-workspace/image-delete",
      {
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_location_list());
    } else {
      toast.error(res.message);
    }
  }
);
// -------------------------------------------------------- add location images----------------------------------------------------------------------
export const add_location_img = createAsyncThunk(
  "addlocationimage",
  async (body) => {
    const response = await fetch(
      base_url + "api/location-workspace/add-images",
      {
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    return res;
  }
);

// -------------------------------------------------------- delete location----------------------------------------------------------------------
export const delete_location = createAsyncThunk(
  "deletelocation",
  async (id) => {
    const response = await fetch(
      base_url + `api/location-workspace/delete/id/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_location_list());
    } else {
      toast.error(res.message);
    }
  }
);
export const get_products_list = createAsyncThunk("product", async (id) => {
  const response = await fetch(base_url + "api/products/list", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();

  return res;
});
export const add_product = createAsyncThunk("addproduct", async (body) => {
  const response = await fetch(base_url + "api/products/add", {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();

  return res;
});
// -------------------------------------------------------- update product priority ----------------------------------------------------------------------

export const update_product_priority = createAsyncThunk(
  "upproduct",
  async (data) => {
    const response = await fetch(
      base_url + `api/products/update/id-priority/${data.id}/${data.priority}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();

    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_products_list());
    } else {
      toast.error(res.message);
    }
  }
);

export const update_product_status = createAsyncThunk(
  "upproductstatus",
  async (data) => {
    const response = await fetch(
      base_url + `api/products/update/id-status/${data.id}/${data.status}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    );
    const res = await response.json();

    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_products_list());
    } else {
      toast.error(res.message);
    }
  }
);

export const delete_product = createAsyncThunk("deleteproduct", async (id) => {
  const response = await fetch(base_url + `api/products/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
export const update_product = createAsyncThunk(
  "updateproduct",
  async (body) => {
    console.log(body);
    const response = await fetch(base_url + `api/products/update`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);
export const get_contact_list = createAsyncThunk("contact", async (data) => {
  const response = await fetch(
    base_url + `api/query-form/list?page=${data.page}&listPerPage=${data.rows}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    }
  );
  const res = await response.json();
  return res;
});

// ------------------------------------------------------------ City List ------------------------------------------------------------------------
export const get_cities_list = createAsyncThunk("city", async (id) => {
  const response = await fetch(base_url + "api/city/list", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});

// ------------------------------------------------------------ Reviews List ------------------------------------------------------------------------

export const get_reviews_list = createAsyncThunk("reviews", async (name) => {
  const response = await fetch(base_url + `api/reviews/list/pageName/${name}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
// ------------------------------------------------------------ Logos List ------------------------------------------------------------------------

export const get_logo_list = createAsyncThunk("logo", async (name) => {
  const response = await fetch(base_url + `api/members-logos/list/${name}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
// ------------------------------------------------------------ Leader List ------------------------------------------------------------------------

export const get_leaders_list = createAsyncThunk("leaders", async () => {
  const response = await fetch(base_url + `api/leaders/list`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});
// ------------------------------------------------------------ Meeting And Event List ------------------------------------------------------------------------

export const get_meetingEvent_list = createAsyncThunk(
  "meetingEvent",
  async () => {
    const response = await fetch(base_url + `api/meetingandevents/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);

// ------------------------------------------------------------ unforgettable-event List ------------------------------------------------------------------------

export const get_unforgettable_event_list = createAsyncThunk(
  "unforgettable_event",
  async () => {
    const response = await fetch(base_url + `api/unforgatableEvent/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);
// ------------------------------------------------------------ Gallery images List ------------------------------------------------------------------------

export const get_gallery_images_list = createAsyncThunk(
  "gallery_images",
  async (name) => {
    const response = await fetch(base_url + `api/eventGallery/list/${name}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);

// ------------------------------------------------------------ choose altis List ------------------------------------------------------------------------

export const get_choose_altis_list = createAsyncThunk(
  "choose_altis",
  async (name) => {
    const response = await fetch(base_url + `api/whyChooseAltis/list/${name}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);
// ------------------------------------------------------------ AmentiesFaci List ------------------------------------------------------------------------

export const get_amenties_faci_list = createAsyncThunk(
  "amenties_faci",
  async (name) => {
    const response = await fetch(base_url + `api/amenAndFaci/list/${name}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);

// ------------------------------------------------------------ Career List ------------------------------------------------------------------------

export const get_career_query_list = createAsyncThunk(
  "career_query",
  async (name) => {
    const response = await fetch(base_url + `api/career/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);
// ------------------------------------------------------------ Career List ------------------------------------------------------------------------

export const get_event_query_list = createAsyncThunk(
  "event_query",
  async () => {
    const response = await fetch(base_url + "api/event/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    });
    const res = await response.json();
    return res;
  }
);

// ------------------------------------------------------------ dropdown List ------------------------------------------------------------------------

export const get_dropdown_list = createAsyncThunk("dropdown", async (name) => {
  const response = await fetch(base_url + `api/dropdowns/list/${name}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.user.token}`,
    },
  });
  const res = await response.json();
  return res;
});

const initialState = {
  banner_list: [],
  banner_loading: false,
  add_banner_loading: false,

  product_list: [],
  product_loading: false,
  addproduct_loading: false,

  location_list: [],
  location_loading: false,
  add_location_loading: false,

  contact_list: [],
  total_contacts: 0,
  contact_loading: false,

  page_list: [],
  page_loading: false,
  add_page_loading: false,

  cities_loading: false,
  cities_list: [],
  add_cities_loading: false,

  review_loading: false,
  review_list: [],

  logo_loading: false,
  logo_list: [],
  add_logo_loading: false,

  leaders_loading: false,
  leaders_list: [],

  meetingEvent_loading: false,
  meetingEvent_list: [],

  unforgettable_event_loading: false,
  unforgettable_event_list: [],

  gallery_images_loading: false,
  gallery_images_list: [],

  choose_altis_loading: false,
  choose_altis_list: [],

  amen_faci_loading: false,
  amen_faci_list: [],

  career_query_loading: false,
  career_query_list: [],

  event_query_loading: false,
  event_query_list: [],

  dropdown_loading: false,
  dropdown_list: [],
};
const homeSlice = createSlice({
  name: "claims",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(add_banner.pending, (state, action) => {
      state.add_banner_loading = true;
    });
    builder.addCase(add_banner.rejected, (state, action) => {
      state.add_banner_loading = false;
    });
    builder.addCase(add_banner.fulfilled, (state, action) => {
      state.add_banner_loading = false;
    });
    builder.addCase(update_banner_priority.pending, (state, action) => {
      state.add_banner_loading = true;
    });
    builder.addCase(update_banner_priority.rejected, (state, action) => {
      state.add_banner_loading = false;
    });
    builder.addCase(update_banner_priority.fulfilled, (state, action) => {
      state.add_banner_loading = false;
    });
    builder.addCase(get_banner_list.pending, (state, action) => {
      state.banner_loading = true;
    });
    builder.addCase(get_banner_list.rejected, (state, action) => {
      state.banner_loading = false;
    });
    builder.addCase(get_banner_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.banner_loading = false;
      if (data.success) {
        state.banner_list = data.data;
      } else {
        state.banner_list = [];
      }
    });
    // --------------------------------------------------------location----------------------------------------------------------------------
    // add Location
    builder.addCase(add_location.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(add_location.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(add_location.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    // delete Location
    builder.addCase(delete_location.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(delete_location.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(delete_location.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    // update Location
    builder.addCase(update_location.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(update_location.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(update_location.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    // update Location image priority
    builder.addCase(update_location_img_priority.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(update_location_img_priority.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(update_location_img_priority.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    // add Location image
    builder.addCase(add_location_img.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(add_location_img.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(add_location_img.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    // delete Location image priority
    builder.addCase(delete_location_img_priority.pending, (state, action) => {
      state.add_location_loading = true;
    });
    builder.addCase(delete_location_img_priority.rejected, (state, action) => {
      state.add_location_loading = false;
    });
    builder.addCase(delete_location_img_priority.fulfilled, (state, action) => {
      state.add_location_loading = false;
    });
    //  Location list
    builder.addCase(get_location_list.pending, (state, action) => {
      state.location_loading = true;
    });
    builder.addCase(get_location_list.rejected, (state, action) => {
      state.location_loading = false;
    });
    builder.addCase(get_location_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.location_loading = false;
      if (data.success) {
        console.log("data.data ====",data.data);
        state.location_list = data.data || [];
      } else {
        state.location_list = [];
      }
    });

    builder.addCase(get_products_list.pending, (state, action) => {
      state.product_loading = true;
    });
    builder.addCase(get_products_list.rejected, (state, action) => {
      state.product_loading = false;
    });
    builder.addCase(add_product.pending, (state, action) => {
      state.addproduct_loading = true;
    });
    builder.addCase(add_product.rejected, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(add_product.fulfilled, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(delete_product.pending, (state, action) => {
      state.addproduct_loading = true;
    });
    builder.addCase(delete_product.rejected, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(delete_product.fulfilled, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(update_product.pending, (state, action) => {
      state.addproduct_loading = true;
    });
    builder.addCase(update_product.rejected, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(update_product.fulfilled, (state, action) => {
      state.addproduct_loading = false;
    });
    builder.addCase(get_products_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.product_loading = false;
      if (data.success) {
        state.product_list = data.data;
      } else {
        state.product_list = [];
      }
    });

    builder.addCase(get_contact_list.pending, (state, action) => {
      state.contact_loading = true;
    });
    builder.addCase(get_contact_list.rejected, (state, action) => {
      state.contact_loading = false;
    });
    builder.addCase(get_contact_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.contact_loading = false;
      if (data.success) {
        state.contact_list = data.data;
        state.total_contacts = data.count;
      } else {
        state.contact_list = [];
      }
    });

    builder.addCase(get_page_list.pending, (state, action) => {
      state.page_loading = true;
    });
    builder.addCase(get_page_list.rejected, (state, action) => {
      state.page_loading = false;
    });

    builder.addCase(get_page_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.page_loading = false;
      if (data.success) {
        state.page_list = data.data;
      } else {
        state.page_list = [];
      }
    });

    // ------------------------------------------------------------ City List ------------------------------------------------------------------------

    builder.addCase(get_cities_list.pending, (state, action) => {
      state.cities_loading = true;
    });
    builder.addCase(get_cities_list.rejected, (state, action) => {
      state.cities_loading = false;
    });

    builder.addCase(get_cities_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.cities_loading = false;
      if (data.success) {
        state.cities_list = data.data;
      } else {
        state.cities_list = [];
      }
    });

    // ------------------------------------------------------------ City List ------------------------------------------------------------------------

    builder.addCase(get_reviews_list.pending, (state, action) => {
      state.review_loading = true;
    });
    builder.addCase(get_reviews_list.rejected, (state, action) => {
      state.review_loading = false;
    });
    builder.addCase(get_reviews_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.review_loading = false;
      if (data.success) {
        state.review_list = data.data;
      } else {
        state.review_list = [];
      }
    });

    // ------------------------------------------------------------ City List ------------------------------------------------------------------------

    builder.addCase(get_logo_list.pending, (state, action) => {
      state.logo_loading = true;
    });
    builder.addCase(get_logo_list.rejected, (state, action) => {
      state.logo_loading = false;
    });
    builder.addCase(get_logo_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.logo_loading = false;
      if (data.success) {
        state.logo_list = data.data;
      } else {
        state.logo_list = [];
      }
    });

    // ------------------------------------------------------------ Leaders List ------------------------------------------------------------------------

    builder.addCase(get_leaders_list.pending, (state, action) => {
      state.leaders_loading = true;
    });
    builder.addCase(get_leaders_list.rejected, (state, action) => {
      state.leaders_loading = false;
    });
    builder.addCase(get_leaders_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.leaders_loading = false;
      if (data.success) {
        state.leaders_list = data.data;
      } else {
        state.leaders_list = [];
      }
    });

    // ------------------------------------------------------------ get_meetingEvent_list List ------------------------------------------------------------------------

    builder.addCase(get_meetingEvent_list.pending, (state, action) => {
      state.meetingEvent_loading = true;
    });
    builder.addCase(get_meetingEvent_list.rejected, (state, action) => {
      state.meetingEvent_loading = false;
    });
    builder.addCase(get_meetingEvent_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.meetingEvent_loading = false;
      if (data.success) {
        state.meetingEvent_list = data.data;
      } else {
        state.meetingEvent_list = [];
      }
    });

    // ------------------------------------------------------------ get_unforgettable_event_list List ------------------------------------------------------------------------

    builder.addCase(get_unforgettable_event_list.pending, (state, action) => {
      state.unforgettable_event_loading = true;
    });
    builder.addCase(get_unforgettable_event_list.rejected, (state, action) => {
      state.unforgettable_event_loading = false;
    });
    builder.addCase(get_unforgettable_event_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.unforgettable_event_loading = false;
      if (data.success) {
        state.unforgettable_event_list = data.data;
      } else {
        state.unforgettable_event_list = [];
      }
    });

    // ------------------------------------------------------------ Gallery Images List ------------------------------------------------------------------------

    builder.addCase(get_gallery_images_list.pending, (state, action) => {
      state.gallery_images_loading = true;
    });
    builder.addCase(get_gallery_images_list.rejected, (state, action) => {
      state.gallery_images_loading = false;
    });
    builder.addCase(get_gallery_images_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.gallery_images_loading = false;
      if (data.success) {
        state.gallery_images_list = data.data;
      } else {
        state.gallery_images_list = [];
      }
    });

    // ------------------------------------------------------------ Choose Alis List ------------------------------------------------------------------------

    builder.addCase(get_choose_altis_list.pending, (state, action) => {
      state.choose_altis_loading = true;
    });
    builder.addCase(get_choose_altis_list.rejected, (state, action) => {
      state.choose_altis_loading = false;
    });
    builder.addCase(get_choose_altis_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.choose_altis_loading = false;
      if (data.success) {
        state.choose_altis_list = data.data;
      } else {
        state.choose_altis_list = [];
      }
    });

    // ------------------------------------------------------------ amenAndFaci/list/ List ------------------------------------------------------------------------

    builder.addCase(get_amenties_faci_list.pending, (state, action) => {
      state.amen_faci_loading = true;
    });
    builder.addCase(get_amenties_faci_list.rejected, (state, action) => {
      state.amen_faci_loading = false;
    });
    builder.addCase(get_amenties_faci_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.amen_faci_loading = false;
      if (data.success) {
        state.amen_faci_list = data.data;
      } else {
        state.amen_faci_list = [];
      }
    });

    // ------------------------------------------------------------ career query List ------------------------------------------------------------------------

    builder.addCase(get_career_query_list.pending, (state, action) => {
      state.career_query_loading = true;
    });
    builder.addCase(get_career_query_list.rejected, (state, action) => {
      state.career_query_loading = false;
    });
    builder.addCase(get_career_query_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.career_query_loading = false;
      if (data.success) {
        state.career_query_list = data.data;
      } else {
        state.career_query_list = [];
      }
    });

    // ------------------------------------------------------------ event query List ------------------------------------------------------------------------

    builder.addCase(get_event_query_list.pending, (state, action) => {
      state.event_query_loading = true;
    });
    builder.addCase(get_event_query_list.rejected, (state, action) => {
      state.event_query_loading = false;
    });
    builder.addCase(get_event_query_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.event_query_loading = false;
      if (data.success) {
        state.event_query_list = data.data;
      } else {
        state.event_query_list = [];
      }
    });

    // ------------------------------------------------------------ dropdown List ------------------------------------------------------------------------

    builder.addCase(get_dropdown_list.pending, (state, action) => {
      state.dropdown_loading = true;
    });
    builder.addCase(get_dropdown_list.rejected, (state, action) => {
      state.dropdown_loading = false;
    });
    builder.addCase(get_dropdown_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.dropdown_loading = false;
      if (data.success) {
        state.dropdown_list = data.data;
      } else {
        state.dropdown_list = [];
      }
    });
  },
});

export default homeSlice.reducer;
