import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { base_url } from "../../utils/utils";
import InputBox from "../../components/InputBox";

const ViewLocationDetails = ({ c_index, isDetailOpen, onDetailClose }) => {
  const { location_list, location_loading, product_list } = useSelector(
    (state) => state.homeData
  );
  var filteredKeywords = product_list.filter((word) =>
    location_list?.[c_index]?.PRODUCTS_IDS.includes(String(word?.ID))
  );

  return (
    <Modal
      isOpen={isDetailOpen}
      // isCentered
      scrollBehavior="inside"
      onClose={() => {
        onDetailClose();
      }}
    >
      <ModalOverlay />
      <ModalContent maxW={"container.md"}>
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        <ModalBody bg={"#fff"} borderRadius={5}>
          <Heading fontSize={25}>Loaction Details</Heading>
          <InputBox
            label={"Location Name"}
            value={location_list?.[c_index]?.LOCATION_NAME}
            textonly={true}
          />
          <InputBox
            label={"City"}
            value={location_list?.[c_index]?.CITY}
            textonly={true}
          />
          <InputBox
            label={"Building Name"}
            value={location_list?.[c_index]?.B_NAME}
            textonly={true}
          />
          <InputBox
            label={"Building Address"}
            value={location_list?.[c_index]?.B_ADDRESS}
            textonly={true}
          />
          <InputBox
            label={"Building Description"}
            value={location_list?.[c_index]?.B_DESCRIPTION}
            textonly={true}
          />
          <InputBox
            label={"Phone Number"}
            value={location_list?.[c_index]?.B_PHONE_NUMBER}
            textonly={true}
          />
          <InputBox
            label={"Building Timing"}
            value={location_list?.[c_index]?.B_TIMINGS}
            textonly={true}
          />
          <InputBox
            label={"Location URL"}
            value={location_list?.[c_index]?.LOCATION_URL}
            textonly={true}
          />
          <InputBox
            label={"Floor"}
            value={location_list?.[c_index]?.NO_FLOOR}
            textonly={true}
          />
          <InputBox
            label={"No Of Meeting Rooms"}
            value={location_list?.[c_index]?.NO_METTING_ROOM}
            textonly={true}
          />
          <InputBox
            label={"Net Size"}
            value={location_list?.[c_index]?.NET_SIZE}
            textonly={true}
          />
          <InputBox
            label={"Conference Room"}
            value={location_list?.[c_index]?.NO_OF_SITTERS}
            textonly={true}
          />

          <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
            Products
          </Text>
          <Stack bg={"#00000008"} p={2}>
            <UnorderedList spacing={1}>
              {filteredKeywords.map((v, i) => (
                <ListItem fontSize={16} fontWeight={"medium"} color={"#2B2B2B"}>
                  {v.TITLE}
                </ListItem>
              ))}
            </UnorderedList>
          </Stack>
          <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={3}>
            Building Features{" "}
          </Text>
          <Stack bg={"#00000008"} p={2}>
            <UnorderedList spacing={1}>
              {location_list?.[c_index]?.B_FEATURES?.map((v, i) => (
                <ListItem fontSize={16} fontWeight={"medium"} color={"#2B2B2B"}>
                  {v}
                </ListItem>
              ))}
            </UnorderedList>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewLocationDetails;
