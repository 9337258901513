import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import {
  delete_banner,
  get_banner_list,
  get_page_list,
  update_banner_priority,
} from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import ImageModal from "../../components/ImageModal";
import CustomPopup from "../../components/CustomPopup";

const BannerList = () => {
  const navigate = useNavigate();
  const { banner_list, banner_loading, page_list, add_banner_loading } =
    useSelector((state) => state.homeData);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [c_page, setC_page] = useState(0);
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [url, setUrl] = useState("");

  useEffect(() => {
    store.dispatch(get_page_list());

    // store.dispatch(get_banner_list(page_list[c_page]?.PAGE_NAME));
    store.dispatch(get_banner_list("Home"));
  }, []);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Slider List</Heading>
        <CustomButton
          title={"Add Slider"}
          onClick={() => navigate("add-slider")}
        />
      </Flex>
      <Spacer h={3} />
      <Text>Page Name</Text>
      <Flex gap={3}>
        {page_list?.map((v, i) => (
          <CustomButton
            bg={c_page != i && "gray"}
            title={v.PAGE_NAME}
            onClick={() => {
              setC_page(i);
              store.dispatch(get_banner_list(page_list[i].PAGE_NAME));
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      {banner_loading && banner_list?.length === 0 ? (
        <Loader />
      ) : banner_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Image",
            "Static Text",
            "Typing Message",
            "PRIORITY",
            "Actions",
          ]}
          body={banner_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                display={"flex"}
              >
                <Image
                  src={base_url + item.IMAGE_URL}
                  h={20}
                  w={20}
                  cursor={"pointer"}
                  resize={"initial"}
                  onClick={() => {
                    setUrl(base_url + item.IMAGE_URL);
                    onViewOpen();
                  }}
                />
              </Td>
              <Td textAlign={"center"}>{item.STATIC_TEXT}</Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.DYNAMIC_TEXT.toString()}
              </Td>
              <Td>
                <Flex justify={"center"}>
                  <Select
                    w={"100px"}
                    value={item.PRIORITY}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) =>
                      store.dispatch(
                        update_banner_priority({
                          id: item.ID,
                          priority: v.target.value,
                          name: page_list[c_page]?.PAGE_NAME,
                        })
                      )
                    }
                  >
                    {Array(banner_list.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Flex>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => navigate("edit-slider", { state: item })}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete slider details"}
        onClick={() => {
          store.dispatch(
            delete_banner({ id: id, name: page_list[c_page].PAGE_NAME })
          );
          onClose();
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default BannerList;
