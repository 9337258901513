import {
  Card,
  Container,
  Flex,
  FormLabel,
  ListItem,
  Radio,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { BiArrowBack } from "react-icons/bi";
import InputBox from "../../components/InputBox";
import SelectImage from "../../components/SelectImage";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { add_product, get_page_list } from "../../redux/slice/homeSlice";
import PageLoader from "../../components/PageLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddProduct = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ status: "1" });
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [keyf, setKeyf] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { addproduct_loading } = useSelector((state) => state.homeData);
  const onAddProduct = () => {
    if (
      !data.title ||
      !data.desc ||
      // !data.priority ||
      !data.img ||
      keyFeatures.length == 0 ||
      !data.status
    ) {
      setData({
        ...data,
        titlemess: !data.title ? "Title is required" : "",
        descmess: !data.desc ? "Description is required" : "",
        // prioritymess: !data.priority ? "Priority is required" : "",
        imgmess: !data.img ? "Image is required" : "",
        statusMess: !data.status ? "Status is required" : "",
      });
      setKeyf({
        ...keyf,
        keymess: keyFeatures.length == 0 ? "Key Features are required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("image", data?.img);
    body.append("title", data?.title);
    body.append("description", data?.desc);
    body.append("status", data?.status);
    // body.append("priority", data.priority);
    // body.append("features", keyFeatures.toString());
    keyFeatures.forEach((v) => body.append("features[]", v));
    store
      .dispatch(add_product(body))
      .unwrap()
      .then((v) => {
        if (v.success) {
          store.dispatch(get_page_list());
          toast.success(v.message);
          navigate(-1);
        } else toast.error(v.message);
      });
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      {/* <PageLoader /> */}
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Product"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Title"}
            placeholder={"Title"}
            value={data.title}
            onChange={(v) =>
              setData({
                ...data,
                title: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                titlemess: "",
              })
            }
            error={data.titlemess}
          />
          <InputBox
            area={true}
            label={"Description"}
            placeholder={"Description"}
            value={data.desc}
            onChange={(v) =>
              setData({
                ...data,
                desc: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                descmess: "",
              })
            }
            error={data.descmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1} flexDirection={"column"}>
            <Flex flex={1} alignItems={"center"} gap={3}>
              <InputBox
                label={"Key Fetures"}
                placeholder={"Key Fetures"}
                value={keyf.key}
                onChange={(v) =>
                  setKeyf({
                    ...keyf,
                    key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                    keymess: "",
                  })
                }
              />
              <CustomButton
                mt={9}
                title={"Add"}
                onClick={() => {
                  setKeyFeatures([...keyFeatures, keyf.key]);
                  setKeyf({
                    ...keyf,
                    key: "",
                  });

                  // setKeyf({});
                  // onOpen();
                }}
              />
            </Flex>
            <Stack bg={"#fff"} flex={1} p={2}>
              <UnorderedList spacing={1}>
                {keyFeatures?.map((v, i) => (
                  <Flex>
                    <ListItem
                      flex={1}
                      fontSize={16}
                      fontWeight={"medium"}
                      color={"#2B2B2B"}
                    >
                      {v}
                    </ListItem>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        const fitered = keyFeatures.filter(
                          (val, ind) => ind != i
                        );
                        setKeyFeatures(fitered);
                      }}
                    />
                  </Flex>
                ))}
              </UnorderedList>
              {keyf.keymess && (
                <Text fontSize={"sm"} color={"#f00"}>
                  {keyf.keymess}
                </Text>
              )}
            </Stack>
          </Flex>
          {/* <InputBox
            label={"Priority"}
            placeholder={"Priority"}
            value={data.priority}
            onChange={(v) =>
              setData({
                ...data,
                priority: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                prioritymess: "",
              })
            }
            error={data.prioritymess}
          /> */}
          <SelectImage
            mt={2}
            label={"Image"}
            doctype={".png,.jpg,.jpeg"}
            filename={data.img?.name}
            onChange={(e) =>
              setData({
                ...data,
                img: e.target.files[0],
                imgval: false,
                imgmess: "",
              })
            }
            errorBorder={data.imgval}
            error={data.imgmess}
          />
        </Flex>
        <RadioGroup
        mt={2}
          value={data?.status}
          onChange={(e) =>
            setData({
              ...data,
              status: e,
              statusMess: "",
            })
          }
        >
          <Text>Status : </Text>

          <Stack direction="row">
            <Radio value="1">Enquire Now</Radio>
            <Radio value="2">Book Now</Radio>
          </Stack>
          {data?.statusMess && (
            <Text mt={2} fontSize={"sm"} color={"#f00"}>
              {data?.statusMess}
            </Text>
          )}
        </RadioGroup>
        {/* <Flex mt={3} gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1} alignItems={"center"} justifyContent={"space-between"}>
            <FormLabel marginBottom={2}>Key Features</FormLabel>
            <CustomButton
              title={"Add"}
              onClick={() => {
                setKeyf({});
                onOpen();
              }}
            />
          </Flex>
          <Flex flex={1}></Flex>
        </Flex>
        <Flex mt={2} gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Stack bg={"#fff"} flex={1} p={2}>
            <UnorderedList spacing={1}>
              {keyFeatures?.map((v, i) => (
                <Flex>
                  <ListItem
                    flex={1}
                    fontSize={16}
                    fontWeight={"medium"}
                    color={"#2B2B2B"}
                  >
                    {v}
                  </ListItem>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      const fitered = keyFeatures.filter(
                        (val, ind) => ind != i
                      );
                      setKeyFeatures(fitered);
                    }}
                  />
                </Flex>
              ))}
            </UnorderedList>
            {keyf.keymess && (
              <Text fontSize={"sm"} color={"#f00"}>
                {keyf.keymess}
              </Text>
            )}
          </Stack>
          <Flex flex={1}></Flex>
        </Flex> */}
        <Flex mt={3} justifyContent={"center"}>
          <CustomButton
            loading={addproduct_loading}
            title={"Submit"}
            onClick={onAddProduct}
          />
        </Flex>
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          title={"Add Key Fetures"}
          mess={
            <InputBox
              area={true}
              value={keyf.key}
              onChange={(v) =>
                setKeyf({
                  ...keyf,
                  key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                })
              }
              placeholder={"Key Feature"}
            />
          }
          b_name={"Add"}
          b_color={"#FFBF00"}
          onClick={() => {
            setKeyFeatures([...keyFeatures, keyf.key]);

            setKeyf({});
            onClose();
          }}
        />
      </Card>
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default AddProduct;
