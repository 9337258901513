import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const InputBox = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  mt,
  info,
  error,
  isInvalid,
  left,
  onlyone,
  area,
  right,
  max,
  min,
  textonly,
  maxL,
  autoFocus,
  errorBorder,
  disabled,
  options,
  drop,
}) => {
  const initialRef = React.useRef(null);
  return (
    <FormControl mt={mt || 2} flex={1}>
      <FormLabel marginBottom={2}>{label}</FormLabel>
      <InputGroup>
        {left && <InputLeftAddon children={left} />}
        {textonly ? (
          <Text
            bg={"#00000008"}
            flex={1}
            w={"full"}
            py={2}
            px={3}
            borderRadius={5}
            minH={10}
          >
            {value}
          </Text>
        ) : area ? (
          <Textarea
            ref={initialRef}
            bg={"#fff"}
            placeholder={placeholder}
            type={type}
            isInvalid={isInvalid}
            errorBorderColor="red.300"
            value={value}
            onChange={onChange}
            borderColor={errorBorder && "#f00"}
          />
        ) : drop ? (
          <Flex flex={1}>
            <Select placeholder={placeholder} value={value} onChange={onChange}>
              {options}
            </Select>
          </Flex>
        ) : (
          <Input
            ref={initialRef}
            placeholder={placeholder}
            type={type}
            isInvalid={isInvalid}
            errorBorderColor="red.300"
            value={value}
            onChange={onChange}
            max={max}
            min={min}
            maxLength={maxL}
            autoFocus={autoFocus}
            borderColor={errorBorder ? "#f00" : "gray.100"}
            disabled={disabled}
            bg={"#fff"}
          />
        )}
        {right && <InputRightElement>{right}</InputRightElement>}
      </InputGroup>
      {error && (
        <Text fontSize={"sm"} color={"#f00"}>
          {error}
        </Text>
      )}
      {info && (
        <Text fontSize={"sm"} mb={0}>
          {info}
        </Text>
      )}
    </FormControl>
  );
};

export default InputBox;
