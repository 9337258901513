import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Spacer,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

import ImageModal from "../../components/ImageModal";
import CustomPopup from "../../components/CustomPopup";
import { base_url } from "../../utils/utils";
import { get_event_query_list } from "../../redux/slice/homeSlice";

const EventQuery = () => {
  const navigate = useNavigate();
  const { event_query_list, event_query_loading } = useSelector(
    (state) => state.homeData
  );
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [url, setUrl] = useState("");

  useEffect(() => {
    store.dispatch(get_event_query_list());
  }, []);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Event Query List</Heading>
      </Flex>
      <Spacer h={3} />

      {event_query_loading && event_query_list?.length === 0 ? (
        <Loader />
      ) : event_query_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "NAME",
            "EMAIL",
            "Company Name",
            "Phone Number",
            "N_GUESTS",
            "LOCATIONS",
            "EVENT_DATE",
            "EVENT_TIME",
            "EVENT_TYPE",
            "ADDITIONAL_NOTES",
            // "Status",
            // "Actions",
          ]}
          body={event_query_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {index + 1}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.NAME}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.EMAIL}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.C_NAME}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.PHONE}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.N_GUESTS}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.LOCATIONS}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.EVENT_DATE}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.EVENT_TIME}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.EVENT_TYPE}
              </Td>

              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.ADDITIONAL_NOTES}
              </Td>

              {/* <Td textAlign={"center"}>
                <Flex justify={"center"}>
                  <Select
                    w={"200px"}
                    value={item.STATUS}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) => {
                      const body = new FormData();
                      body.append("status", v.target.value);
                      store.dispatch(
                        update_query_status({
                          id: item.ID,
                          body: body,
                        })
                      );
                    }}
                  >
                    {["In Process", "Resolved", "Not Resolved"].map((v, i) => (
                      <option value={v}>{v}</option>
                    ))}
                  </Select>
                </Flex>
              </Td> */}
              {/* <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td> */}
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete query"}
        onClick={() => {
          onClose();
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default EventQuery;
