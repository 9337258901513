import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

const ImageModal = ({ url = "", isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
      }}
    >
      <ModalOverlay />
      <ModalContent maxW={"container.xl"} h={"2xl"}>
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        <ModalBody h={"full"} bg={"#fff"} borderRadius={5}>
          <Image
            src={url}
            maxW={"container.xl"}
            w={"full"}
            objectFit={"contain"}
            maxH={"100%"}
            h={"2xl"}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
