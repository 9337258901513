import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import ImageModal from "../../components/ImageModal";
import TableView from "../../components/TableView";
import { base_url } from "../../utils/utils";
import CustomPopup from "../../components/CustomPopup";
import store from "../../redux/store";
import {
  add_location_img,
  delete_location,
  delete_location_img_priority,
  get_location_list,
  update_location_img_priority,
} from "../../redux/slice/homeSlice";
import HeadingText from "../../components/HeadingText";
import SelectImage from "../../components/SelectImage";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";

const LocationImages = ({ c_index, isIDetailOpen, onIDetailClose }) => {
  const { location_list, location_loading } = useSelector(
    (state) => state.homeData
  );
  const [url, setUrl] = useState("");
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [id, setId] = useState("");
  const [images, setImages] = useState([]);
  const onAddLocationImage = () => {
    if (images.length == 0) {
      toast.info("Please select images");
      return;
    }
    const body = new FormData();
    body.append("ID", location_list?.[c_index]?.ID);
    images.forEach((v) => body.append("PHOTOS", v));
    store
      .dispatch(add_location_img(body))
      .unwrap()
      .then((v) => {
        if (v.success) {
          setImages([]);
          store.dispatch(get_location_list());
          toast.success(v.message);
          onAddClose();
        } else {
          toast.error(v.message);
        }
      });
  };
  return (
    <>
      <Modal
        isOpen={isIDetailOpen}
        onClose={() => {
          onIDetailClose();
          //   setImages([]);
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.xl"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Flex gap={3}>
              <Container h={"full"} w={"full"} maxW={"full"}>
                <Flex justifyContent={"space-between"} pr={5}>
                  <HeadingText title={"Slider Image Details"} />
                  <CustomButton
                    title={"Add Image"}
                    onClick={() => onAddOpen()}
                  />
                </Flex>
                <Spacer mb={5} />
                {location_list?.[c_index]?.IMAGE_URLS?.length == 0 ? (
                  <Center bg={"#fff"} borderRadius={10} py={3}>
                    <Heading fontSize={25}>No Data Found</Heading>
                  </Center>
                ) : (
                  <TableView
                    headData={["S.No.", "Image", "Priority", "Actions"]}
                    body={location_list?.[c_index]?.IMAGE_URLS?.map(
                      (item, index) => (
                        <Tr key={index}>
                          <Td textAlign={"center"}>{index + 1}</Td>
                          <Td textAlign={"center"}>
                            <Image
                              onClick={() => {
                                setUrl(base_url + item.path);
                                onViewOpen();
                              }}
                              cursor={"pointer"}
                              src={base_url + item.path}
                              w={20}
                              objectFit={"contain"}
                              h={20}
                              bg={"#c1c1c122"}
                            />
                          </Td>
                          <Td textAlign={"center"}>
                            <Flex justify={"center"}>
                              <Select
                                w={"100px"}
                                value={item.priority}
                                size={"md"}
                                borderRadius={5}
                                onChange={(v) => {
                                  const body = new FormData();
                                  body.append("ID", location_list?.[c_index]?.ID);
                                  body.append("PATH_ID", item.path_id);
                                  body.append("PRIORITY", v.target.value);
                                  store.dispatch(
                                    update_location_img_priority(body)
                                  );
                                }}
                              >
                                {Array(
                                  location_list?.[c_index]?.IMAGE_URLS?.length + 1
                                )
                                  ?.fill("")
                                  ?.map((v, i) => (
                                    <option value={i}>{i}</option>
                                  ))}
                              </Select>
                            </Flex>
                          </Td>
                          <Td textAlign={"center"}>
                            <Flex gap={3} justify={"center"}>
                              <FiTrash2
                                size={20}
                                cursor={"pointer"}
                                onClick={() => {
                                  setId(item.path_id);
                                  onOpen();
                                }}
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      )
                    )}
                  />
                )}
              </Container>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete slider image"}
        onClick={() => {
          const body = new FormData();
          body.append("ID", location_list?.[c_index]?.ID);
          body.append("PATH_ID", id);
          store.dispatch(delete_location_img_priority(body));
          onClose();
        }}
      />
      <CustomPopup
        isOpen={isAddOpen}
        onClose={onAddClose}
        title={"Add slider images"}
        mess={
          <Flex flex={1} flexDirection={"column"}>
            <SelectImage
              mt={2}
              multiple={true}
              label={"Images"}
              doctype={".png,.jpg,.jpeg"}
              onChange={(e) => {
                setImages([...e.target.files]);
              }}
              // errorBorder={data.imgval}
            />
            <Stack bg={"#fff"} flex={1} p={2}>
              <UnorderedList spacing={1}>
                {images?.map((v, i) => (
                  <Flex>
                    <ListItem
                      flex={1}
                      fontSize={16}
                      fontWeight={"medium"}
                      color={"#2B2B2B"}
                    >
                      {v.name}
                    </ListItem>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        const fitered = images.filter((val, ind) => ind != i);
                        setImages(fitered);
                      }}
                    />
                  </Flex>
                ))}
              </UnorderedList>
            </Stack>
          </Flex>
        }
        b_name={"Add"}
        b_color={"#FFBF00"}
        onClick={() => {
          onAddLocationImage();
          onClose();
        }}
      />

      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
    </>
  );
};

export default LocationImages;
