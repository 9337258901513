import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import {
  delete_location,
  get_cities_list,
  get_location_list,
  get_products_list,
} from "../../redux/slice/homeSlice";
import ImageModal from "../../components/ImageModal";
import CustomPopup from "../../components/CustomPopup";
import { base_url } from "../../utils/utils";
import LocationImages from "./LocationImages";
import ViewLocationDetails from "./ViewLocationDetails";

const LoactionList = () => {
  const navigate = useNavigate();
  const { location_list, location_loading } = useSelector(
    (state) => state.homeData
  );

  console.log("location_list ====",location_list);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const {
    isOpen: isDetailOpen,
    onClose: onDetailClose,
    onOpen: onDetailOpen,
  } = useDisclosure();
  const {
    isOpen: isImgOpen,
    onClose: onImgClose,
    onOpen: onImgOpen,
  } = useDisclosure();

  const [url, setUrl] = useState("");
  const [c_index, setC_index] = useState("");

  useEffect(() => {
    store.dispatch(get_cities_list());
    store.dispatch(get_products_list());
    store.dispatch(get_location_list());
  }, []);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Location List</Heading>
        <CustomButton
          title={"Add Location"}
          onClick={() => navigate("add-location")}
        />
      </Flex>
      <Spacer h={3} />

      {location_loading && location_list?.length === 0 ? (
        <Loader />
      ) : location_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Loaction Name",
            "City",
            "Building Name",
            "Phone Number",
            "Email",
            "Timing",
            "ADDRESS",
            "Floor",
            "No of Meeting Rooms",
            "Net Size",
            "Conference room",
            "Slider Images",
            "Actions",
          ]}
          body={location_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.LOCATION_NAME}</Td>
              <Td textAlign={"center"}>{item.CITY}</Td>
              <Td textAlign={"center"}>{item.B_NAME}</Td>
              <Td textAlign={"center"}>{item.B_PHONE_NUMBER}</Td>
              <Td textAlign={"center"}>{item.B_EMAIL}</Td>
              <Td textAlign={"center"}>{item.B_TIMINGS}</Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.B_ADDRESS}
                </Text>
              </Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.NO_FLOOR}
                </Text>
              </Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.NO_METTING_ROOM}
                </Text>
              </Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.NET_SIZE}
                </Text>
              </Td>
              <Td textAlign={"center"}>
                <Text isTruncated={true} maxW={300}>
                  {item.NO_OF_SITTERS}
                </Text>
              </Td>

              <Td textAlign={"center"}>
                <CustomButton
                  title={"View"}
                  onClick={() => {
                    // setImages(item.IMAGE_URLS);
                    setC_index(index);
                    onImgOpen();
                  }}
                />
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setC_index(index);
                      onDetailOpen();
                    }}
                  />

                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => navigate("edit-location", { state: item })}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete location details"}
        onClick={() => {
          store.dispatch(delete_location(id));
          onClose();
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      {/* 
        */}
        <LocationImages
        isIDetailOpen={isImgOpen}
        onIDetailClose={onImgClose}
        c_index={c_index}
      />
      <ViewLocationDetails
        isDetailOpen={isDetailOpen}
        onDetailClose={onDetailClose}
        c_index={c_index}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default LoactionList;
