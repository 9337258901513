import {
  Center,
  Container,
  Flex,
  Heading,
  Select,
  Spacer,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { get_contact_list } from "../../redux/slice/homeSlice";
import CustomPopup from "../../components/CustomPopup";
import CustomButton from "../../components/CustomButton";
import InputBox from "../../components/InputBox";
import { put_api } from "../../utils/apis";
import { toast } from "react-toastify";

const ContactsList = () => {
  const navigate = useNavigate();
  const { contact_list, contact_loading, page_list, total_contacts } =
    useSelector((state) => state.homeData);
  const [id, setId] = useState("");
  const [comment, setComment] = useState({});
  const {
    isOpen: isCOpen,
    onClose: onCClose,
    onOpen: onCOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const [initialValue, setInitialValue] = useState(1);
  useEffect(() => {
    store.dispatch(get_contact_list({ rows: rows, page: page }));
  }, []);
  const onComment = async () => {
    if (!comment.data) {
      setComment({
        ...comment,
        error: !comment.data ? "Please add comment" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("ID", id);
    body.append("COMMENT", comment.data);
    const res = await put_api(`query-form/comment`, body, setLoading);
    if (res.success) {
      store.dispatch(get_contact_list({ rows: rows, page: page }));
      onCClose();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>User Enquiry List</Heading>
      </Flex>
      <Spacer h={3} />

      {contact_loading && contact_list?.length === 0 ? (
        <Loader />
      ) : contact_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "First Name",
            "Last Name",
            "Company Name",
            "Company Email",
            "Phone Number",
            "Location",
            "Product Type",
            "Company Size",
            "Description",
            "Status",
            "Comment",
            // "COMMENT Action",
            // "Actions",
          ]}
          body={contact_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {index + initialValue}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.F_NAME}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.L_NAME}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.COMPANY_NAME}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.COMPANY_EMAIL}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.PHONE_NUMBER}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.LOCATIONS}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.PRODUCT_TYPE}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                {item.COMPANY_SIZE}
              </Td>
              <Td
                textAlign={"center"}
                maxW={"350px"}
                whiteSpace={"pre-wrap"}
                width={"100%"}
              >
                {item.DESCRIPTION}
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                <Flex justify={"center"}>
                  <Select
                    w={"200px"}
                    value={item.STATUS}
                    size={"md"}
                    borderRadius={5}
                    onChange={async (v) => {
                      const body = new FormData();
                      body.append("status", v.target.value);
                      const res = await put_api(
                        `query-form/status/id/${item.ID}`,
                        body,
                        setLoading
                      );
                      if (res.success) {
                        store.dispatch(
                          get_contact_list({ rows: rows, page: page })
                        );
                        toast.success(res.message);
                      } else {
                        toast.error(res.message);
                      }
                    }}
                  >
                    {["In Process", "Resolved", "Not Resolved"].map((v, i) => (
                      <option value={v}>{v}</option>
                    ))}
                  </Select>
                </Flex>
              </Td>
              <Td textAlign={"center"} maxW={"350px"} whiteSpace={"pre-wrap"}>
                <Center>
                  {item.COMMENT?.length == 0 ? (
                    <CustomButton
                      title={"Add Comment"}
                      onClick={() => {
                        setId(item.ID);
                        setComment({});
                        onCOpen();
                      }}
                    />
                  ) : (
                    <Flex gap={2}>
                      {item.COMMENT}
                      <FiEdit
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(item.ID);
                          setComment({
                            ...comment,
                            data: item.COMMENT,
                          });
                          onCOpen();
                        }}
                      />
                    </Flex>
                  )}
                </Center>
              </Td>
            </Tr>
          ))}
          pagination
          total_value={total_contacts}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            store.dispatch(get_contact_list({ page: 1, rows: v.target.value }));
            setInitialValue(1);
            setpage(1);
          }}
          rows={rows}
          page={page}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page == 1 ||
              store.dispatch(get_contact_list({ page: page - 1, rows: rows }));
          }}
          onNext={() => {
            rows * page >= total_contacts ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_contacts || setpage(page + 1);
            rows * page >= total_contacts ||
              store.dispatch(get_contact_list({ page: page + 1, rows: rows }));
          }}
        />
      )}
      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        mess={
          <InputBox
            label={"Comment"}
            placeholder={"Comment"}
            value={comment.data}
            area={true}
            onChange={(v) => {
              setComment({ ...comment, data: v.target.value, error: "" });
            }}
            error={comment.error}
          />
        }
        onClick={() => {
          onComment();
        }}
        b_color={"#FFBF00"}
        b_name={"Submit"}
      />

      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default ContactsList;
