import { Button } from "@chakra-ui/react";
import React from "react";

const CustomButton = ({ title, loading, onClick, icon, bg, mt }) => {
  return (
    <Button
      variant={"solid"}
      h={30}
      bg={bg || "#FFBF00"}
      _hover={{ bg: `${bg}+77` || "#FFBF0099" }}
      color={"#fff"}
      isLoading={loading}
      onClick={onClick}
      // alignSelf={"flex-start"}
      gap={2}
      mt={mt}
    >
      {icon}
      {title}
    </Button>
  );
};

export default CustomButton;
