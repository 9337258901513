import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./screens/dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/auth/Login";
import { Heading, Text } from "@chakra-ui/react";
import BannerList from "./screens/homeBanner/BannerList";
import AddBanner from "./screens/homeBanner/AddBanner";
import EditBanner from "./screens/homeBanner/EditBanner";
import ContactsList from "./screens/contacts/ContactsList";
import ProductList from "./screens/products/ProductList";
import LoactionList from "./screens/location/LoactionList";
import AddLocation from "./screens/location/AddLocation";
import AddProduct from "./screens/products/AddProduct";
import EditProduct from "./screens/products/EditProduct";
import EditLocation from "./screens/location/EditLocation";
import Cities from "./screens/city";
import ReviewList from "./screens/reviews/ReviewList";
import LogoList from "./screens/logos/LogoList";
import LeadersList from "./screens/leader/LeadersList";
import MeetingEventList from "./screens/meetingEvent/MeetingEventList";
import UnforgettableEventList from "./screens/unforgettableEvent/UnforgettableEventList";
import GalleryImageList from "./screens/galleryImage/GalleryImageList";
import ChooseAltis from "./screens/chooseAltis/ChooseAltis";
import AmentiesFaci from "./screens/AmentiesFaci/AmentiesFaci";
import CareerQuery from "./screens/CarrerQuery/CareerQuery";
import EventQuery from "./screens/EventQuery/EventQuery";
import { useEffect } from "react";
import Dropdown from "./screens/dropdown/Dropdown";

function App() {
  const user = JSON.parse(localStorage.getItem("userId"));
  const navigate = useNavigate();
  useEffect(() => {
    if (user != null) {
      navigate("/slider");
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          {/* <Route index element={<Dashboard />} /> */}
          <Route path="slider" element={<Outlet />}>
            <Route index element={<BannerList />} />
            <Route path="add-slider" element={<AddBanner />} />
            <Route path="edit-slider" element={<EditBanner />} />
          </Route>
          <Route path="location" element={<Outlet />}>
            <Route index element={<LoactionList />} />
            <Route path="add-location" element={<AddLocation />} />
            <Route path="edit-location" element={<EditLocation />} />
          </Route>
          <Route path="products" element={<Outlet />}>
            <Route index element={<ProductList />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="edit-product" element={<EditProduct />} />
          </Route>
          <Route path="contacts" element={<Outlet />}>
            <Route index element={<ContactsList />} />
          </Route>
          <Route path="cities" element={<Outlet />}>
            <Route index element={<Cities />} />
          </Route>
          <Route path="reviews" element={<Outlet />}>
            <Route index element={<ReviewList />} />
          </Route>
          <Route path="logos" element={<LogoList />}></Route>
          <Route path="leaders" element={<LeadersList />}></Route>
          <Route path="meeting-event" element={<MeetingEventList />}></Route>
          <Route
            path="unforgettable-event"
            element={<UnforgettableEventList />}
          ></Route>
          <Route path="gallery-images" element={<GalleryImageList />}></Route>
          <Route path="choose-altis" element={<ChooseAltis />}></Route>
          <Route path="amenties-faci" element={<AmentiesFaci />}></Route>
          <Route path="career-query" element={<CareerQuery />}></Route>
          <Route path="event-query" element={<EventQuery />}></Route>
          <Route path="dropdown" element={<Dropdown />}></Route>
        </Route>
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#ffbf00"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;
