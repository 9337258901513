import {
  Center,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Td,
  Tr,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import { add_api, delete_api, put_api } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import { get_dropdown_list } from "../../redux/slice/homeSlice";
import InputBox from "../../components/InputBox";

const list = ["Current openings", "Interested in"];

const Dropdown = () => {
  const navigate = useNavigate();
  const { dropdown_list, dropdown_loading } = useSelector(
    (state) => state.homeData
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const [c_page, setC_page] = useState(0);
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    store.dispatch(get_dropdown_list(list[c_page]));
  }, []);

  const onAddlogo = async () => {
    if (!data.name || !data.pagename) {
      setData({
        ...data,
        namemess: !data.name ? "Dropdown Value is Required" : "",
        pagenameMess: !data.pagename ? "Dropdown name is Required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("NAME", data.name);
    body.append("TYPE", data.pagename);

    const res = await add_api("dropdowns/add", body, setLoading);
    if (res.success) {
      store.dispatch(get_dropdown_list(list[c_page]));
      onAddClose();
      setData({});
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Dropdown List</Heading>

        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Text mb={2}>Dropdown Name</Text>
      <Flex gap={3}>
        {list.map((v, i) => (
          <CustomButton
            bg={c_page != i && "gray"}
            title={v}
            onClick={() => {
              setC_page(i);
              store.dispatch(get_dropdown_list(v));
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      {dropdown_loading && dropdown_list?.length === 0 ? (
        <Loader />
      ) : dropdown_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Dropdown Value", "Actions"]}
          body={dropdown_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td justifyContent={"center"} display={"flex"}>
                {item.NAME}
              </Td>

              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      setData({ ...data, name: item.NAME });
                      onViewOpen();
                    }}
                  />
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText title={"Add Company Logo"} />
            <InputBox
              drop={true}
              label={"Dropdown Name"}
              placeholder={"Dropdown Name"}
              value={data?.pagename}
              onChange={(v) => {
                setData({
                  ...data,
                  pagename: v.target.value,
                  pagenameMess: "",
                });
              }}
              error={data?.pagenameMess}
              options={list?.map((v) => (
                <option value={v}>{v}</option>
              ))}
            />

            <InputBox
              label={"Dropdown Value"}
              placeholder={"Dropdown Value"}
              value={data.name}
              onChange={(v) =>
                setData({
                  ...data,
                  name: v.target.value,
                  namemess: "",
                })
              }
              error={data?.namemess}
            />

            <Center mt={5} mb={2}>
              <CustomButton
                title={"Submit"}
                loading={loading}
                onClick={onAddlogo}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CustomPopup
        b_color={"#FFBF00"}
        b_name={"Update"}
        isOpen={isViewOpen}
        onClose={onViewClose}
        onClick={() => {
          const body = new FormData();
          body.append("NAME", data.name);
          body.append("TYPE", list[c_page]);
          body.append("ID", id);
          add_api(`dropdowns/update`, body, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(get_dropdown_list(list[c_page]));
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onViewClose();
        }}
        title={"Update"}
        mess={
          <InputBox
            label={"Dropdown Value"}
            placeholder={"Dropdown Value"}
            value={data.name}
            onChange={(v) =>
              setData({
                ...data,
                name: v.target.value,
                namemess: "",
              })
            }
            error={data?.namemess}
          />
        }
      />
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`dropdowns/${id}`, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(get_dropdown_list(list[c_page]));
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to delete dropdown value"}
      />
    </Container>
  );
};

export default Dropdown;
