import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import {
  add_api,
  delete_api,
  delete_review,
  get_api,
  update_leader_priority,
} from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";

import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";
import { get_unforgettable_event_list } from "../../redux/slice/homeSlice";

const UnforgettableEventList = () => {
  const navigate = useNavigate();
  const { unforgettable_event_list, unforgettable_event_loading } = useSelector(
    (state) => state.homeData
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_unforgettable_event_list());
  }, []);

  const onAddData = async () => {
    if (
      !data.NAME ||
      !data.CITY ||
      // !data.COUNT ||
      !data.DESCRIPTION ||
      (!edit && !data.img)
    ) {
      setData({
        ...data,
        NAMEMess: !data.NAME ? "Name is Required" : "",
        CITYMess: !data.CITY ? "City is Required" : "",
        // COUNTMess: !data.COUNT ? "Count is Required" : "",
        DESCRIPTIONMess: !data.DESCRIPTION ? "Description is Required" : "",
        imgMess: !edit && !data.img ? "Image is Required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("NAME", data.NAME);
    body.append("CITY", data.CITY);
    body.append("COUNT", 0);
    // body.append("COUNT", data.COUNT);
    body.append("DESCRIPTION", data.DESCRIPTION);
    // body.append("PRIORITY", !data.PRIORITY ? 0 : data.PRIORITY);
    body.append("IMAGE", data.img);
    edit && body.append("ID", data.ID);
    const res = await add_api(
      edit ? "unforgatableEvent/update" : "unforgatableEvent/add",
      body,
      setLoading
    );
    if (res.success) {
      store.dispatch(get_unforgettable_event_list());
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const update_priority = async (id, id1) => {
    const res = await get_api(
      `unforgatableEvent/update/id/priority/${id}/${id1}`,
      setLoading
    );
    if (res.success) {
      store.dispatch(get_unforgettable_event_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Unforgettable Event</Heading>
        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />

      {unforgettable_event_loading && unforgettable_event_list?.length === 0 ? (
        <Loader />
      ) : unforgettable_event_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Main Heading",
            // "COUNT",
            "Sub Heading",
            "DESCRIPTION",
            "Image",
            // "PRIORITY",
            "Actions",
          ]}
          body={unforgettable_event_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.NAME}</Td>
              {/* <Td textAlign={"center"}>{item.COUNT}</Td> */}
              <Td textAlign={"center"}>{item.CITY}</Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.DESCRIPTION}
              </Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Image
                    onClick={() => {
                      setUrl(base_url + item.IMAGE_URL);
                      onViewOpen();
                    }}
                    src={base_url + item.IMAGE_URL}
                    height={"60px"}
                    w={"60px"}
                    objectFit={"cover"}
                  />
                </Center>
              </Td>
              {/* <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Select
                    w={"100px"}
                    value={item.PRIORITY}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) => update_priority(item.ID, v.target.value)}
                  >
                    {Array(unforgettable_event_list?.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Center>
              </Td> */}
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      onPOpen();
                    }}
                  />
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={async () => {
                      setData(item);
                      setEdit(true);
                      onAddOpen();
                    }}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText
              title={
                edit
                  ? "Edit Unforgettable Event Details"
                  : "Add Unforgettable Event"
              }
            />
            <InputBox
              label={"Main Heading"}
              placeholder={"Main Heading"}
              value={data.NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  NAME: v.target.value.trimStart(),
                  NAMEMess: "",
                });
              }}
              error={data?.NAMEMess}
            />
            {/* <InputBox
              label={"Count (Number)"}
              placeholder={"Count"}
              value={data.COUNT}
              onChange={(v) => {
                setData({
                  ...data,
                  COUNT: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                  COUNTMess: "",
                });
              }}
              error={data?.COUNTMess}
            /> */}
            <InputBox
              label={"Sub Heading"}
              placeholder={"Sub Heading"}
              value={data.CITY}
              onChange={(v) => {
                setData({
                  ...data,
                  CITY: v.target.value.trimStart(),
                  CITYMess: "",
                });
              }}
              error={data?.CITYMess}
            />
            <InputBox
              label={"Description"}
              placeholder={"Description"}
              area={true}
              value={data.DESCRIPTION}
              onChange={(v) => {
                setData({
                  ...data,
                  DESCRIPTION: v.target.value.trimStart(),
                  DESCRIPTIONMess: "",
                });
              }}
              error={data?.DESCRIPTIONMess}
            />

            <SelectImage
              mt={2}
              label={"Image"}
              filename={data?.img?.name}
              onChange={(e) => {
                setData({
                  ...data,
                  img: e.target.files[0],
                  imgMess: "",
                });
              }}
              error={data?.imgMess}
            />
            {/* <InputBox
              drop={true}
              label={"Priority"}
              value={data.PRIORITY}
              onChange={(v) =>
                setData({
                  ...data,
                  PRIORITY: v.target.value,
                  PRIORITYmess: "",
                })
              }
              options={Array(unforgettable_event_list?.length + 1)
                .fill("")
                ?.map((item, index) => (
                  <option>{index}</option>
                ))}
            /> */}

            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddData}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Modal
        isOpen={isPOpen}
        onClose={() => {
          onPClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.md"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Heading fontSize={25}>Unforgettable Event Details</Heading>
            <InputBox label={"Name"} value={data.NAME} textonly />
            <InputBox label={"Count"} value={data.COUNT} textonly />
            <InputBox label={"City"} value={data.CITY} textonly />
            <InputBox label={"Description"} value={data.DESCRIPTION} textonly />
            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={2}>
              IMAGE_URL
            </Text>
            <Image
              src={base_url + data.IMAGE_URL}
              h={200}
              w={200}
              cursor={"pointer"}
              resize={"initial"}
              onClick={() => {
                setUrl(base_url + data.IMAGE_URL);
                onViewOpen();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`unforgatableEvent/delete/id/${id}`, setLoading).then(
            (res) => {
              if (res.success) {
                store.dispatch(get_unforgettable_event_list());
                toast.success(res.message);
              } else {
                toast.error(res.message);
              }
            }
          );
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to Unforgettable Event detail"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default UnforgettableEventList;
