import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import otherSlice from "./slice/otherSlice";
import usersSlice from "./slice/usersSlice";
import homeSlice from "./slice/homeSlice";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  other: otherSlice,
  users: usersSlice,

  homeData: homeSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
export const persistor = persistStore(store);
