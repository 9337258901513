import {
  Center,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_cities_list } from "../../redux/slice/homeSlice";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import { city_actions, delete_city } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import InputBox from "../../components/InputBox";

const Cities = () => {
  const navigate = useNavigate();
  const { cities_list, cities_loading } = useSelector(
    (state) => state.homeData
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_cities_list());
  }, []);

  const onAddCity = async () => {
    if (!data.CITY_NAME || !data.CITY_DESCRIPTION) {
      setData({
        ...data,
        cityMess: !data.CITY_NAME ? "City Name is Required" : "",
        descMess: !data.CITY_DESCRIPTION ? "City Description is Required" : "",
      });
      return;
    }
    const body = new FormData();
    edit && body.append("ID", data.id);
    body.append("CITY_NAME", data.CITY_NAME);
    body.append("CITY_DESCRIPTION", data.CITY_DESCRIPTION);

    const res = await city_actions(edit ? "update" : "add", body, setLoading);
    if (res.success) {
      store.dispatch(get_cities_list());
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Cities List</Heading>
        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />

      {cities_loading && cities_list?.length === 0 ? (
        <Loader />
      ) : cities_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "CITY NAME", "CITY DESCRIPTION", "Actions"]}
          body={cities_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.CITY_NAME}</Td>
              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.CITY_DESCRIPTION}
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      setEdit(true);
                      onAddOpen();
                    }}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.id);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText
              title={edit ? "Edit City Details" : "Add City Details"}
            />
            <InputBox
              label={"City Name"}
              placeholder={"City Name"}
              value={data.CITY_NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  CITY_NAME: v.target.value,
                  cityMess: "",
                });
              }}
              error={data?.cityMess}
            />
            <InputBox
              label={"City Description"}
              placeholder={"City Description"}
              area={true}
              value={data.CITY_DESCRIPTION}
              onChange={(v) => {
                setData({
                  ...data,
                  CITY_DESCRIPTION: v.target.value,
                  descMess: "",
                });
              }}
              error={data?.descMess}
            />
            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddCity}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_city(id, setLoading);
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to delete city"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default Cities;
