import {
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import {
  add_api,
  delete_api,
  delete_review,
  update_leader_priority,
} from "../../utils/apis";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { toast } from "react-toastify";
import {
  get_amenties_faci_list,
  get_location_list,
} from "../../redux/slice/homeSlice";
import { base_url } from "../../utils/utils";
import SelectImage from "../../components/SelectImage";
import ImageModal from "../../components/ImageModal";
import InputBox from "../../components/InputBox";

const AmentiesFaci = () => {
  const navigate = useNavigate();
  const { amen_faci_list, amen_faci_loading, page_list, location_list } =
    useSelector((state) => state.homeData);
  const [c_page, setC_page] = useState(0);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onClose: onPClose,
    onOpen: onPOpen,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    store.dispatch(get_location_list());
    store.dispatch(get_amenties_faci_list(location_list[c_page].LOCATION_NAME));
  }, []);

  const onAddData = async () => {
    if (!data.NAME || !data.PAGE_NAME || (!edit && !data.img)) {
      setData({
        ...data,
        nameMess: !data.NAME ? "Name is Required" : "",
        PAGE_NAMEMess: !data.PAGE_NAME ? "Page Name is Required" : "",
        imgMess: !edit && !data.img ? "Image is Required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("NAME", data.NAME);
    body.append("PAGE_NAME", data.PAGE_NAME);
    body.append("PRIORITY", !data.PRIORITY ? 0 : data.PRIORITY);
    body.append("IMAGE", data.img);
    edit && body.append("ID", data.ID);
    const res = await add_api(
      edit ? "amenAndFaci/update" : "amenAndFaci/add",
      body,
      setLoading
    );
    if (res.success) {
      store.dispatch(
        get_amenties_faci_list(location_list[c_page].LOCATION_NAME)
      );
      onAddClose();
      setData({});
      setEdit(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const list = [];
  for (let i = 0; i < page_list.length; i++) {
    const element = {
      label: page_list[i].PAGE_NAME,
      value: page_list[i].PAGE_NAME,
    };
    list.push(element);
  }
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Amenities & Facilities List</Heading>
        <CustomButton
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setEdit(false);
            setData({});
            onAddOpen();
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Text>Location Name</Text>
      <Flex gap={3}>
        {location_list.map((v, i) => (
          <CustomButton
            bg={c_page != i && "gray"}
            title={v.LOCATION_NAME}
            onClick={() => {
              setC_page(i);
              store.dispatch(
                get_amenties_faci_list(location_list[i].LOCATION_NAME)
              );
            }}
          />
        ))}
      </Flex>
      <Spacer h={3} />
      {amen_faci_loading && amen_faci_list?.length === 0 ? (
        <Loader />
      ) : amen_faci_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10} py={3}>
          <Heading fontSize={25}>No Data Found</Heading>
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "NAME", "Image", "PRIORITY", "Actions"]}
          body={amen_faci_list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.NAME}</Td>

              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                <Center>
                  <Image
                    onClick={() => {
                      setUrl(base_url + item.IMAGE_URL);
                      onViewOpen();
                    }}
                    src={base_url + item.IMAGE_URL}
                    height={"60px"}
                    w={"60px"}
                    objectFit={"cover"}
                  />
                </Center>
              </Td>

              <Td textAlign={"center"} maxW={"300px"} whiteSpace={"pre-wrap"}>
                {item.PRIORITY}
                {/* <Center>
                  <Select
                    w={"100px"}
                    value={item.PRIORITY}
                    size={"md"}
                    borderRadius={5}
                    onChange={(v) =>
                      update_leader_priority(item.ID, v.target.value)
                    }
                  >
                    {Array(amen_faci_list?.length + 1)
                      ?.fill("")
                      ?.map((v, i) => (
                        <option value={i}>{i}</option>
                      ))}
                  </Select>
                </Center> */}
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setData(item);
                      onPOpen();
                    }}
                  />
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={async () => {
                      setData(item);
                      setEdit(true);
                      onAddOpen();
                    }}
                  />

                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setId(item.ID);
                      onOpen();
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <Modal
        isOpen={isAddOpen}
        isCentered
        scrollBehavior="inside"
        onClose={() => {
          onAddClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.sm"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <HeadingText
              title={
                edit ? "Edit Amenties & Faci Details" : "Add Amenties & Faci"
              }
            />
            <InputBox
              drop={true}
              label={"Location Name"}
              placeholder={"Location Name"}
              value={data.PAGE_NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  PAGE_NAME: v.target.value,
                  PAGE_NAMEMess: "",
                });
              }}
              error={data?.PAGE_NAMEMess}
              options={location_list.map((val, ind) => (
                <option value={val.LOCATION_NAME}>{val.LOCATION_NAME}</option>
              ))}
            />
            <InputBox
              label={"Name"}
              placeholder={"Name"}
              value={data.NAME}
              onChange={(v) => {
                setData({
                  ...data,
                  NAME: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  nameMess: "",
                });
              }}
              error={data?.nameMess}
            />

            <SelectImage
              mt={2}
              label={"Image"}
              filename={data?.img?.name}
              onChange={(e) => {
                setData({
                  ...data,
                  img: e.target.files[0],
                  imgMess: "",
                });
              }}
              error={data?.imgMess}
            />
            <InputBox
              drop={true}
              label={"Priority"}
              value={data.PRIORITY}
              onChange={(v) =>
                setData({
                  ...data,
                  PRIORITY: v.target.value,
                  PRIORITYmess: "",
                })
              }
              options={Array(
                edit ? amen_faci_list?.length + 1 : amen_faci_list?.length + 2
              )
                .fill("")
                ?.map((item, index) => (
                  <option>{index}</option>
                ))}
            />

            <Center mt={5} mb={2}>
              <CustomButton
                title={edit ? "Update" : "Submit"}
                loading={loading}
                onClick={onAddData}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      <Modal
        isOpen={isPOpen}
        onClose={() => {
          onPClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"container.md"}>
          <ModalCloseButton zIndex={100} bg={"#99999988"} />
          <ModalBody bg={"#fff"} borderRadius={5}>
            <Heading fontSize={25}>Amenties&Faci Details</Heading>
            <InputBox label={"Name"} value={data.NAME} textonly />

            <Text fontSize={16} fontWeight={"medium"} color={"#5B5050"} mt={2}>
              IMAGE_URL
            </Text>
            <Image
              src={base_url + data.IMAGE_URL}
              h={200}
              w={200}
              cursor={"pointer"}
              resize={"initial"}
              onClick={() => {
                setUrl(base_url + data.IMAGE_URL);
                onViewOpen();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          delete_api(`amenAndFaci/delete/id/${id}`, setLoading).then((res) => {
            if (res.success) {
              store.dispatch(
                get_amenties_faci_list(location_list[c_page].LOCATION_NAME)
              );
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          });
          onClose();
        }}
        title={"Delete"}
        mess={"Are you sure? You want to Amenties & Faci detail"}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default AmentiesFaci;
