import {
  Card,
  Center,
  Container,
  Flex,
  FormLabel,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import InputBox from "../../components/InputBox";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import { add_banner, edit_banner } from "../../redux/slice/homeSlice";

const EditBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [keyf, setKeyf] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { page_list, add_banner_loading } = useSelector(
    (state) => state.homeData
  );

  useEffect(() => {
    setData({ ...data });
  }, []);
  useEffect(() => {
    setData({
      ...data,
      title: location.state.STATIC_TEXT,
      p_name: location.state.PAGE_NAME,
      priority: location.state.PRIORITY,
    });
    setKeyFeatures(location.state.DYNAMIC_TEXT);
  }, []);

  const onAddSlider = () => {
    if (
      !data.p_name ||
      !data.title ||
      // !data.priority ||
      // !data.img ||
      keyFeatures.length == 0
    ) {
      setData({
        ...data,
        p_namemess: !data.p_name ? "Page Name is required" : "",
        titlemess: !data.title ? "Title is required" : "",
        // prioritymess: !data.priority ? "Priority is required" : "",
        // imgmess: !data.img ? "Image is required" : "",
      });
      setKeyf({
        ...keyf,
        keymess: keyFeatures.length == 0 ? "Typing Message are required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("id", location.state.ID);
    data?.img?.name && body.append("image", data.img);
    body.append("staticText", data.title);
    body.append("pageName", data.p_name);
    // body.append("priority", data.priority);

    keyFeatures.forEach((v) => body.append("dynamicText[]", v));
    store
      .dispatch(edit_banner(body))
      .unwrap()
      .then((v) => {
        if (v.success) {
          toast.success(v.message);
          navigate(-1);
        } else toast.error(v.message);
      });
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Edit Slider Details"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            mt={0.1}
            drop={true}
            label={"Page Name"}
            // placeholder={"Page Name"}
            value={data.p_name}
            onChange={(v) =>
              setData({
                ...data,
                p_name: v.target.value,
                p_namemess: "",
              })
            }
            error={data.p_namemess}
            options={page_list.map((v) => (
              <option value={v.PAGE_NAME}>{v.PAGE_NAME}</option>
            ))}
          />
          <SelectImage
            label={"Image"}
            doctype={".png,.jpg,.jpeg"}
            filename={data.img?.name}
            onChange={(e) =>
              setData({
                ...data,
                img: e.target.files[0],
                imgval: false,
                imgmess: "",
              })
            }
            errorBorder={data.imgval}
            error={data.imgmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Static Text"}
            placeholder={"Static Text"}
            value={data.title}
            onChange={(v) =>
              setData({
                ...data,
                title: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                titlemess: "",
              })
            }
            error={data.titlemess}
          />
          <Flex flex={1} flexDirection={"column"}>
            <Flex flex={1} alignItems={"center"} gap={3}>
              <InputBox
                label={"Typing Message"}
                placeholder={"Typing Message"}
                value={keyf.key}
                onChange={(v) =>
                  setKeyf({
                    ...keyf,
                    key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                    keymess: "",
                  })
                }
              />
              <CustomButton
                mt={9}
                title={"Add"}
                onClick={() => {
                  setKeyFeatures([...keyFeatures, keyf.key]);
                  setKeyf({
                    ...keyf,
                    key: "",
                  });

                  // setKeyf({});
                  // onOpen();
                }}
              />
            </Flex>
            <Stack bg={"#fff"} flex={1} p={2}>
              <UnorderedList spacing={1}>
                {keyFeatures?.map((v, i) => (
                  <Flex>
                    <ListItem
                      flex={1}
                      fontSize={16}
                      fontWeight={"medium"}
                      color={"#2B2B2B"}
                    >
                      {v}
                    </ListItem>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        const fitered = keyFeatures.filter(
                          (val, ind) => ind != i
                        );
                        setKeyFeatures(fitered);
                      }}
                    />
                  </Flex>
                ))}
              </UnorderedList>
              {keyf.keymess && (
                <Text fontSize={"sm"} color={"#f00"}>
                  {keyf.keymess}
                </Text>
              )}
            </Stack>
          </Flex>
          {/* <InputBox
            label={"Priority"}
            placeholder={"Priority"}
            value={data.priority}
            onChange={(v) =>
              setData({
                ...data,
                priority: v.target.value.trim(),
                prioritymess: "",
              })
            }
            error={data.prioritymess}
          /> */}
        </Flex>
        <Flex mt={3} gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1}></Flex>
        </Flex>
        <Flex mt={2} gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1}></Flex>
        </Flex>
        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={add_banner_loading}
            onClick={() => onAddSlider()}
          />
        </Center>
      </Card>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Add Typing Message"}
        mess={
          <InputBox
            area={true}
            value={keyf.key}
            onChange={(v) =>
              setKeyf({
                ...keyf,
                key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
              })
            }
            placeholder={"Typing Message"}
          />
        }
        b_name={"Add"}
        b_color={"#FFBF00"}
        onClick={() => {
          setKeyFeatures([...keyFeatures, keyf.key]);

          setKeyf({});
          onClose();
        }}
      />
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default EditBanner;
