import {
  Card,
  Container,
  Flex,
  FormLabel,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import HeadingText from "../../components/HeadingText";
import { BiArrowBack } from "react-icons/bi";
import InputBox from "../../components/InputBox";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import {
  get_location_list,
  update_location,
} from "../../redux/slice/homeSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";

const EditLocation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [keyf, setKeyf] = useState({});
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { add_location_loading, product_list, cities_list } = useSelector(
    (state) => state.homeData
  );
  const [images, setImages] = useState([]);
  const location = useLocation();
  const list = [];
  for (let i = 0; i < product_list.length; i++) {
    const element = {
      label: product_list[i].TITLE,
      value: product_list[i].ID,
    };
    list.push(element);
  }
  useEffect(() => {
    var filteredKeywords = list.filter((word) =>
      location.state?.PRODUCTS_IDS.includes(String(word.value))
    );
    setData({
      ...data,
      state: location.state.LOCATION_NAME,
      city: location.state.CITY_ID,
      b_name: location.state.B_NAME,
      b_add: location.state.B_ADDRESS,
      b_desc: location.state.B_DESCRIPTION,
      mobile: location.state.B_PHONE_NUMBER,
      email: location.state.B_EMAIL,
      timing: location.state.B_TIMINGS,
      Products: filteredKeywords,
      location_url: location.state.LOCATION_URL,
      net_size: location.state.NET_SIZE,
      m_rooms: location.state.NO_METTING_ROOM,
      Floor: location.state.NO_FLOOR,
      conference_room: location.state.NO_OF_SITTERS,
    });
    setKeyFeatures(location.state.B_FEATURES);
  }, []);
  const oneditLocation = () => {
    if (
      !data.state ||
      !data.city ||
      !data.b_name ||
      !data.b_add ||
      !data.b_desc ||
      !data.mobile ||
      !data.email ||
      !data.timing ||
      !data.location_url ||
      //   images.length == 0|| ||
      keyFeatures.length == 0 ||
      !data.Floor ||
      !data.conference_room ||
      !data.m_rooms ||
      !data.net_size
    ) {
      setData({
        ...data,
        statemess: !data.state ? "State is required" : "",
        citymess: !data.city ? "City is required" : "",
        b_namemess: !data.b_name ? "Name is required" : "",
        b_addmess: !data.b_add ? "Address is required" : "",
        b_descmess: !data.b_desc ? "Description is required" : "",
        mobilemess: !data.mobile ? "Mobile Number is required" : "",
        emailmess: !data.email ? "Email Address is required" : "",
        timingmess: !data.timing ? "Timing is required" : "",
        location_urlmess: !data.location_url ? "Location url is required" : "",
        Floormess: !data?.Floor ? "Floor is required" : "",
        conference_roommess: !data?.conference_room
          ? "Conference Room is required"
          : "",
        net_sizemess: !data?.net_size ? "Net size is required" : "",
        m_roomsmess: !data?.m_rooms ? "Meeting rooms are required" : "",
        // imgmess: images.length == 0 ? "Images is required" : "",
      });
      setKeyf({
        ...keyf,
        keymess: keyFeatures.length == 0 ? "Features are required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("ID", location.state.ID);
    body.append(
      "CITY",
      cities_list?.filter((v) => v.id == data?.city)[0]?.CITY_NAME
    );
    body.append("CITY_ID", data.city);
    body.append("LOCATION_NAME", data.state);
    body.append("LOCATION_URL", data.location_url);
    body.append("B_NAME", data.b_name);
    body.append("B_ADDRESS", data.b_add);
    body.append("B_DESCRIPTION", data.b_desc);
    body.append("B_PHONE_NUMBER", data.mobile);
    body.append("B_EMAIL", data.email);
    body.append("B_TIMINGS", data.timing);
    body.append("NO_FLOOR", data.Floor);
    body.append("NO_METTING_ROOM", data.m_rooms);
    body.append("NET_SIZE", data.net_size);
    body.append("NO_OF_SITTERS", data.conference_room);
    keyFeatures.forEach((v) => body.append("B_FEATURES[]", v));
    data?.Products.forEach((v) => body.append("PRODUCTS_IDS[]", v.value));

    store
      .dispatch(update_location(body))
      .unwrap()
      .then((v) => {
        if (v.success) {
          store.dispatch(get_location_list());
          toast.success(v.message);
          navigate(-1);
        } else toast.error(v.message);
      });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #f00",
    }),
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Edit Location"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Location Name"}
            placeholder={"Location Name"}
            value={data.state}
            onChange={(v) =>
              setData({
                ...data,
                state: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                statemess: "",
              })
            }
            error={data.statemess}
          />
          <InputBox
            drop={true}
            label={"City"}
            placeholder={"Select City"}
            value={data.city}
            onChange={(v) =>
              setData({
                ...data,
                city: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                citymess: "",
              })
            }
            options={cities_list?.map((v, i) => (
              <option value={v.id} key={i}>
                {v.CITY_NAME}
              </option>
            ))}
            error={data.citymess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Building Name"}
            placeholder={"Building Name"}
            value={data.b_name}
            onChange={(v) =>
              setData({
                ...data,
                b_name: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                b_namemess: "",
              })
            }
            error={data.b_namemess}
          />
          <InputBox
            label={"Building Address"}
            placeholder={"Building Address"}
            area={true}
            value={data.b_add}
            onChange={(v) =>
              setData({
                ...data,
                b_add: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                b_addmess: "",
              })
            }
            error={data.b_addmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Building Description"}
            placeholder={"Building Description"}
            area={true}
            value={data.b_desc}
            onChange={(v) =>
              setData({
                ...data,
                b_desc: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                b_descmess: "",
              })
            }
            error={data.b_descmess}
          />
          <InputBox
            label={"Mobile Number"}
            placeholder={"Mobile Number"}
            value={data.mobile}
            onChange={(v) =>
              setData({
                ...data,
                mobile: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                mobilemess: "",
              })
            }
            error={data.mobilemess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Email Address"}
            placeholder={"Email Address"}
            value={data.email}
            onChange={(v) =>
              setData({
                ...data,
                email: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                emailmess: "",
              })
            }
            error={data.emailmess}
          />
          <InputBox
            label={"Timing"}
            placeholder={"Timing"}
            value={data.timing}
            onChange={(v) =>
              setData({
                ...data,
                timing: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                timingmess: "",
              })
            }
            error={data.timingmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <Flex flex={1} flexDirection={"column"}>
            <Flex flex={1} alignItems={"center"} gap={3}>
              <InputBox
                label={"Features"}
                placeholder={"Features"}
                value={keyf.key}
                onChange={(v) =>
                  setKeyf({
                    ...keyf,
                    key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                    keymess: "",
                  })
                }
              />
              <CustomButton
                mt={9}
                title={"Add"}
                onClick={() => {
                  setKeyFeatures([...keyFeatures, keyf.key]);
                  setKeyf({
                    ...keyf,
                    key: "",
                  });

                  // setKeyf({});
                  // onOpen();
                }}
              />
            </Flex>
            {keyf.keymess && (
              <Text fontSize={"sm"} color={"#f00"}>
                {keyf.keymess}
              </Text>
            )}
            <Stack bg={"#fff"} flex={1} p={2}>
              <UnorderedList spacing={1}>
                {keyFeatures?.map((v, i) => (
                  <Flex>
                    <ListItem
                      flex={1}
                      fontSize={16}
                      fontWeight={"medium"}
                      color={"#2B2B2B"}
                    >
                      {v}
                    </ListItem>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        const fitered = keyFeatures.filter(
                          (val, ind) => ind != i
                        );
                        setKeyFeatures(fitered);
                      }}
                    />
                  </Flex>
                ))}
              </UnorderedList>
            </Stack>
          </Flex>
          <InputBox
            mt={0.1}
            label={"Location URL"}
            placeholder={"Location URL"}
            value={data.location_url}
            onChange={(v) =>
              setData({
                ...data,
                location_url: v.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
                location_urlmess: "",
              })
            }
            error={data.location_urlmess}
          />
        </Flex>
        <Flex gap={3} mt={3}>
          <div style={{ flex: 1 }}>
            <FormLabel marginBottom={2} marginLeft={2}>
              Select Products
            </FormLabel>
            <Select
              placeholder={"Search and Select Products"}
              options={list}
              isMulti={true}
              value={data.Products}
              isSearchable
              // filterOption={customFilter}
              onChange={(v) => {
                setData({
                  ...data,
                  Products: v,
                  Productsmess: "",
                  Productsval: false,
                });
              }}
              styles={data.Productsval && customStyles}
            />
            {
              <Text fontSize={"sm"} color={"#f00"}>
                {data.Productsmess}
              </Text>
            }
          </div>
          <Flex flex={1} flexDirection={"column"}></Flex>
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Floor"}
            placeholder={"Floor"}
            value={data.Floor}
            onChange={(v) =>
              setData({
                ...data,
                Floor: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                Floormess: "",
              })
            }
            error={data.Floormess}
          />

          <InputBox
            label={"No of Meeting Rooms"}
            placeholder={"No of Meeting Rooms"}
            value={data.m_rooms}
            onChange={(v) =>
              setData({
                ...data,
                m_rooms: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                m_roomsmess: "",
              })
            }
            error={data.m_roomsmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={["column", "column", "row", "row"]}>
          <InputBox
            label={"Net Size"}
            placeholder={"Net Size"}
            value={data.net_size}
            onChange={(v) =>
              setData({
                ...data,
                net_size: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                net_sizemess: "",
              })
            }
            error={data.net_sizemess}
          />

          <InputBox
            label={"Conference room"}
            placeholder={"Conference room"}
            value={data.conference_room}
            onChange={(v) =>
              setData({
                ...data,
                conference_room: v.target.value
                  .trimStart()
                  .replace(/\s{2,}/g, " "),
                conference_roommess: "",
              })
            }
            error={data.conference_roommess}
          />
        </Flex>
        <Flex mt={3} justifyContent={"center"}>
          <CustomButton
            loading={add_location_loading}
            title={"Update"}
            onClick={oneditLocation}
          />
        </Flex>
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          title={"Add Fetures"}
          mess={
            <InputBox
              area={true}
              value={keyf.key}
              onChange={(v) =>
                setKeyf({
                  ...keyf,
                  key: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                })
              }
              placeholder={"Key Feature"}
            />
          }
          b_name={"Add"}
          b_color={"#FFBF00"}
          onClick={() => {
            setKeyFeatures([...keyFeatures, keyf.key]);

            setKeyf({});
            onClose();
          }}
        />
      </Card>
      <Flex h={8} w={100} bg={"transparent"} />
    </Container>
  );
};

export default EditLocation;
